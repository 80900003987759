import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { withTranslation } from 'react-i18next';

export class Login extends Component {
    state = {
        username: '',
        password: '',
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password);
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { t } = this.props;
        if (this.props.isAuthenticated) {
            return <Navigate to="/" />;
        }
        const { username, password } = this.state;
        return (
            <>
                <div className="col-md-6 m-auto mb-5">
                    <div className="card card-body mt-5">
                        <h2 className="text-center">{t("welcomeKraphX")}</h2>
                        <p style={{ whiteSpace: "pre-wrap" }} className="mt-3">
                            {t('welcomeMessage')}
                        </p>
                        <div className='text-center mt-3 mb-3'>
                            <a href='https://www.kraphx.com' target={'_blank'} className='btn primary-grey me-2'>
                                {t('learnMore')}
                            </a>
                        </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group mt-2">
                                <label>{t("username")}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="username"
                                    onChange={this.onChange}
                                    value={username}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label>{t("password")}</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    onChange={this.onChange}
                                    value={password}
                                />
                            </div>

                            <div className="form-group mt-4 text-center">
                                <button type="submit" className="btn btn-lg primary">
                                    {t("login")}
                                </button>
                            </div>
                            <p className='mt-3 text-center'>
                                {t("accountMissing")} <Link to="/register">{t("register")}</Link>
                            </p>
                        </form>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(withTranslation()(Login));