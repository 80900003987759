import React, { useState, useEffect, useRef } from "react";
import DataService from "../../services/DataService";
import { returnErrors, createMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import { t } from "i18next";
import homeImage from "../../img/home.png";
import searchResultsImage from "../../img/searchResults.png";
import learningPathImage from "../../img/learningPath.png";
import relationsImage from "../../img/relations.png";
import resourcesImage from "../../img/resources.png";
import topicsImage from "../../img/topics.png";
import linkImage from "../../img/link.png";


const onClick = (dispatch, setShow, setProfile, tutorial) => {
    setShow(false);
    if (setProfile != false) {
        DataService.setProfile(tutorial)
            .then((res) => { })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }
};


export const HomeTutorial = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [position, setPosition] = useState(0);

    const getText = () => {
        if (position === 0) return t("homeTutorial1");
        if (position === 1) return t("homeTutorial2");
        if (position === 2) return t("homeTutorial3");
    }

    const text = getText();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "home") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t("homeTutorialHeading")}</h4>
                </div>
            </div>
            <img src={homeImage} className="img-fluid" alt="..."></img>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {text}
            </p>
            <br></br>
            <div className="row">
                <div className="col-6">
                    {position !== 0
                        ?
                        <button
                            className="btn btn-light w-100"
                            onClick={() => { setPosition(position - 1) }}>
                            {t("previous")}
                        </button>
                        : <></>
                    }
                </div>
                <div className="col-6">
                    {position !== 2
                        ? <button
                            className="btn primary w-100"
                            onClick={() => { setPosition(position + 1); }}>
                            {t("next")}
                        </button>
                        : <button
                            className="btn primary w-100"
                            onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "home") }}>
                            {t("letsGo")}
                        </button>
                    }
                </div>
            </div>
            <p className="text-center mt-3" style={{ whiteSpace: "pre-wrap" }}>
                {position + 1} of 3
            </p>
            <div className="text-center">
                <a href="https://storyset.com/web">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const SearchResultTutorial = (props) => {
    const dispatch = useDispatch();
    const [position, setPosition] = useState(0);

    const getText = () => {
        if (position === 0) return t("searchResultsTutorial1");
        if (position === 1) return t("searchResultsTutorial2");
    }

    const text = getText();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "search") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t("searchResultsTutorialHeading")}</h4>
                </div>
            </div>
            <img src={searchResultsImage} className="img-fluid" alt="..."></img>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {text}
            </p>
            <br></br>
            <div className="row">
                <div className="col-6">
                    {position !== 0
                        ?
                        <button
                            className="btn btn-light w-100"
                            onClick={() => { setPosition(position - 1) }}>
                            {t("previous")}
                        </button>
                        : <></>
                    }
                </div>
                <div className="col-6">
                    {position !== 1
                        ? <button
                            className="btn primary w-100"
                            onClick={() => { setPosition(position + 1); }}>
                            {t("next")}
                        </button>
                        : <button
                            className="btn primary w-100"
                            onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "search") }}>
                            {t("letsGo")}
                        </button>
                    }
                </div>
            </div>
            <p className="text-center mt-3" style={{ whiteSpace: "pre-wrap" }}>
                {position + 1} of 2
            </p>
            <div className="text-center">
                <a href="https://storyset.com/web">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const PathTutorial = (props) => {
    const dispatch = useDispatch();
    const [position, setPosition] = useState(0);

    const getText = () => {
        if (position === 0) return t("pathTutorial1");
        if (position === 1) return t("pathTutorial2");
        if (position === 2) return t("pathTutorial3");
    }

    const text = getText();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "path") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t("pathTutorialHeading")}</h4>
                </div>
            </div>
            <img src={learningPathImage} className="img-fluid" alt="..."></img>
            <br></br>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {text}
            </p>
            <br></br>
            <div className="row">
                <div className="col-6">
                    {position !== 0
                        ?
                        <button
                            className="btn btn-light w-100"
                            onClick={() => { setPosition(position - 1) }}>
                            {t("previous")}
                        </button>
                        : <></>
                    }
                </div>
                <div className="col-6">
                    {position !== 2
                        ? <button
                            className="btn primary w-100"
                            onClick={() => { setPosition(position + 1); }}>
                            {t("next")}
                        </button>
                        : <button
                            className="btn primary w-100"
                            onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "path") }}>
                            {t("letsGo")}
                        </button>
                    }
                </div>
            </div>
            <p className="text-center mt-3" style={{ whiteSpace: "pre-wrap" }}>
                {position + 1} of 3
            </p>
            <div className="text-center">
                <a href="https://storyset.com/nature">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const RelationsTutorial = (props) => {
    const dispatch = useDispatch();
    const [position, setPosition] = useState(0);

    const getText = () => {
        if (position === 0) return t("relationsTutorial1");
        if (position === 1) return t("relationsTutorial2");
    }

    const text = getText();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "relations") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t('relationsTutorialHeading')}</h4>
                </div>
            </div>
            <img src={relationsImage} className="img-fluid" alt="..."></img>
            <br></br>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {text}
            </p>
            <br></br>
            <div className="row">
                <div className="col-6">
                    {position !== 0
                        ?
                        <button
                            className="btn btn-light w-100"
                            onClick={() => { setPosition(position - 1) }}>
                            {t("previous")}
                        </button>
                        : <></>
                    }
                </div>
                <div className="col-6">
                    {position !== 1
                        ? <button
                            className="btn primary w-100"
                            onClick={() => { setPosition(position + 1); }}>
                            {t("next")}
                        </button>
                        : <button
                            className="btn primary w-100"
                            onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "relations") }}>
                            {t("letsGo")}
                        </button>
                    }
                </div>
            </div>
            <p className="text-center mt-3" style={{ whiteSpace: "pre-wrap" }}>
                {position + 1} of 2
            </p>
            <div className="text-center">
                <a href="https://storyset.com/technology">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const ResourcesTutorial = (props) => {
    const dispatch = useDispatch();
    const [position, setPosition] = useState(0);

    const getText = () => {
        if (position === 0) return t("resourcesTutorial1");
        if (position === 1) return t("resourcesTutorial2");
    }

    const text = getText();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "resources") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t('resourcesTutorialHeading')}</h4>
                </div>
            </div>
            <img src={resourcesImage} className="img-fluid" alt="..."></img>
            <br></br>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {text}
            </p>
            <br></br>
            <div className="row">
                <div className="col-6">
                    {position !== 0
                        ?
                        <button
                            className="btn btn-light w-100"
                            onClick={() => { setPosition(position - 1) }}>
                            {t("previous")}
                        </button>
                        : <></>
                    }
                </div>
                <div className="col-6">
                    {position !== 1
                        ? <button
                            className="btn primary w-100"
                            onClick={() => { setPosition(position + 1); }}>
                            {t("next")}
                        </button>
                        : <button
                            className="btn primary w-100"
                            onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "resources") }}>
                            {t("letsGo")}
                        </button>
                    }
                </div>
            </div>
            <p className="text-center mt-3" style={{ whiteSpace: "pre-wrap" }}>
                {position + 1} of 2
            </p>
            <div className="text-center">
                <a href="https://storyset.com/online">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const URLTutorial = (props) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "url") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t('urlTutorialHeading')}</h4>
                </div>
            </div>
            <img src={linkImage} className="img-fluid" alt="..."></img>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {t("urlTutorial")}
            </p>
            <br></br>
            <button
                className="btn primary w-100"
                onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "url") }}>
                {t("letsGo")}
            </button>
            <div className="text-center mt-3">
                <a href="https://storyset.com/web">Illustration by Storyset</a>
            </div>
        </>
    );
};


export const TopicsTutorial = (props) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="row">
                <div className="col-1">
                    <h4
                        onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "topics") }}
                        style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </h4>
                </div>
                <div className="col-10">
                    <h4 className="text-center">{t('topicsTutorialHeading')}</h4>
                </div>
            </div>
            <img src={topicsImage} className="img-fluid" alt="..."></img>
            <br></br>
            <br></br>
            <p style={{ whiteSpace: "pre-wrap" }}>
                {t("topicsTutorial")}
            </p>
            <br></br>
            <button
                className="btn primary w-100"
                onClick={() => { onClick(dispatch, props.setShow, props.setProfile, "topics") }}>
                {t("letsGo")}
            </button>
            <div className="text-center mt-3">
                <a href="https://storyset.com/people">Illustration by Storyset</a>
            </div>
        </>
    );
};