import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ResourceSearchSelect, ResourceSearchMultiSelect, ResourceCompactSelect, ResourceCompactButtons } from "./ResourceUtils";
import { SearchSelect } from "./Search";
import { returnErrors, createMessage } from "../../actions/messages";
import { ResourceCompact } from "./Resources";
import { useParams } from 'react-router-dom';
import { Card, ComponentTable, Select, ModalBody, Accordion, makeid } from "./Utils";
import DataService from "../../services/DataService";
import { ResourcesTutorial, RelationsTutorial, TopicsTutorial, URLTutorial } from "./Tutorial";
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import { EdgeList } from "./Profile";
import { ContentCompactEditDelete } from "./ResourceUtils";
import { useLocation } from 'react-router-dom';
import linkImage from "../../img/link.png";
import pdfImage from "../../img/pdf.png";


export const AddRelation = (props) => {
    const [relationType, setRelationType] = useState(props.relationType == undefined ? null : props.relationType);
    const [sourceGiven, setSourceGiven] = useState(props.sourceGiven);
    const [sourceContent, setSourceContent] = useState(null);
    const [destinationContent, setDestinationContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const location = useLocation()

    useEffect(() => {
        DataService.getProfile()
            .then((res) => {
                if (res.data.relations) setShowModal(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        if (id != undefined) {
            DataService.getResource(id)
                .then((res) => {
                    if (sourceGiven) {
                        setSourceContent(res.data);
                    } else {
                        setDestinationContent(res.data);
                    }
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    }, []);

    useEffect(() => {
        setRelationType(props.relationType == undefined ? null : props.relationType);
        setSourceGiven(props.sourceGiven);
        setSourceContent(null);
        setDestinationContent(null);
        setShowModal(false);
    }, [location]);

    const submitRelation = () => {
        let apiEndpoint = null;
        // c for consolidation
        if (relationType === 'c') {
            apiEndpoint = DataService.addEdgeProposal;
            // r for requirement
        } else if (relationType === 'r') {
            apiEndpoint = DataService.addDependencyEdgeProposal;
            // a for alternative
        } else if (relationType === 'a') {
            apiEndpoint = DataService.addSimilarity;
        }
        // dependency edges from A to B means that B is required for A
        // path edge from A to B means that B deepens understanding of A
        // => source becomes destination and destination becomes source
        apiEndpoint(destinationContent.id, sourceContent.id)
            .then((res) => {
                dispatch(createMessage({ edgeSubmitted: t('submissionSuccessful') }));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getRelationText = () => {
        if (relationType === 'c') {
            return t("deepensUnderstandingOf");
        } else if (relationType === 'r') {
            return t("requiredToUnderstand");
        } else if (relationType === 'a') {
            return t("isAlternative");
        }
    }

    const relation = <Relation
        sourceContent={sourceContent}
        destinationContent={destinationContent}
        setSourceContent={setSourceContent}
        setDestinationContent={setDestinationContent}
        relationText={getRelationText()}
        editable={true}
    />

    return (
        <>
            {relationType == undefined
                ? <EdgeTypeDialog setRelationType={setRelationType} />
                : sourceContent == undefined
                    ? <ContentDialog
                        setContent={setSourceContent}
                        headline={t('chooseContentA')}
                        relation={relation}
                        key={'contentA'}
                    />
                    : destinationContent == undefined
                        ? <ContentDialog
                            setContent={setDestinationContent}
                            headline={t('chooseContentB')}
                            relation={relation}
                            key={'contentB'}
                        />
                        : <SubmissionDialog
                            submit={submitRelation}
                            relation={relation}
                        />
            }
            <Offcanvas show={showModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                <Offcanvas.Body>
                    <RelationsTutorial setShow={setShowModal} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};


export const Relation = (props) => {
    const [sourceContent, setSourceContent] = useState(null);
    const [destinationContent, setDestinationContent] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.sourceContent != undefined) {
            setSourceContent(props.sourceContent);
        } else {
            setSourceContent(null);
        }
        if (props.destinationContent != undefined) {
            setDestinationContent(props.destinationContent);
        } else {
            setDestinationContent(null);
        }
    }, [props.sourceContent, props.destinationContent]);

    const buildContentComponent = (content, isSource) => {
        const stateFunction = isSource ? props.setSourceContent : props.setDestinationContent;
        if (props.editable) {
            return <ResourceCompactButtons resource={content} buttons={
                <div className="row">
                    <div className="col-6">
                        <button
                            type="button"
                            className="btn btn-light w-100"
                            onClick={() => { stateFunction(null) }}>{t('edit')}
                        </button>
                    </div>
                    <div className="col-6">
                        <button
                            type="button"
                            className="btn btn-light w-100"
                            onClick={() => { props.setSourceContent(destinationContent); props.setDestinationContent(sourceContent) }}>{t('swap')}
                        </button>
                    </div>
                </div>
            } />
        } else {
            return <Card resource={<ResourceCompact resource={content} />} />
        }
    }

    return (
        <div className="row">
            <div className="col-5 align-self-center">
                {sourceContent != undefined
                    ? buildContentComponent(sourceContent, true)
                    : <Card resource={<div className="text-center"><h5>{t('contentA')}</h5></div>} />
                }
            </div>
            <div className="col-2 text-center align-self-center">
                <div className="align-self-center">
                    <h5>{props.relationText}</h5>
                </div>
            </div>
            <div className="col-5 align-self-center">
                {destinationContent != undefined
                    ? buildContentComponent(destinationContent, false)
                    : <Card resource={<div className="text-center"><h5>{t('contentB')}</h5></div>} />
                }
            </div>
        </div>
    );
}


export const SubmissionDialog = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <h4 className='text-center mb-5'>{t('checkAndSubmit')}</h4>
            <div className="mb-5">
                {props.relation}
            </div>
            <div className="row mb-2 align-items-center">
                <div className="col text-center">
                    <button className="btn primary" onClick={props.submit}>{t('submit')}</button>
                </div>
            </div>
        </>
    );
};


export const ContentDialog = (props) => {
    const { t, i18n } = useTranslation();

    const select = (content) => {
        props.setContent(content);
    }

    return (
        <>
            <h4 className='text-center mb-5'>{props.headline}</h4>
            <div className="mb-5">
                {props.relation}
            </div>
            <SearchSelect
                searchResults={undefined}
                searchTerm={""}
                select={select}
                selectText={t('choose')}
            />
        </>
    );
};


export const EdgeTypeDialog = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <h4 className='text-center mb-5'>{t('edgeTypeDialog')}</h4>
            <div className="row mb-5">
                <div className="col-10 align-self-center">
                    <Relation
                        relationText={t('requiredToUnderstand')}
                    />
                </div>
                <div className="col-2 align-self-center">
                    <div className="text-center">
                        <button className="btn primary" onClick={() => { props.setRelationType('r') }}>{t('addRequirement')}</button>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-10 align-self-center">
                    <Relation
                        relationText={t('deepensUnderstandingOf')}
                    />
                </div>
                <div className="col-2 align-self-center">
                    <div className="text-center">
                        <button className="btn primary" onClick={() => { props.setRelationType('c') }}>{t('addConsolidation')}</button>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-10 align-self-center">
                    <Relation
                        relationText={t('isAlternative')}
                    />
                </div>
                <div className="col-2 align-self-center">
                    <div className="text-center">
                        <button className="btn primary" onClick={() => { props.setRelationType('a') }}>{t('addAlternative')}</button>
                    </div>
                </div>
            </div>
        </>
    );
};


function isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
}


export const AddResource = (props) => {
    const defaultResource = {
        title: "",
        description: "",
        link: "",
        author: "",
        category: 'Category',
        language: 'Language',
        fees: true,
        duration: "",
        segmenting: false,
    }
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [parentResource, setParentResource] = useState(null);
    const [extractedContent, setExtractedContent] = useState(null);
    const [searchResults, setSearchResults] = useState(undefined);
    const [resource, setResource] = useState(defaultResource);
    const [isKnowledge, setIsKnowledge] = useState(false);
    const [overrideChildren, setOverrideChildren] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [previewLink, setPreviewLink] = useState("");
    const [image, setImage] = useState("");
    const [extended, setExtended] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showURLTutorialModal, setShowURLTutorialModal] = useState(false);
    const [requirementProposals, setRequirementProposals] = useState([]);
    const [consolidationProposals, setConsolidationProposals] = useState([]);
    const [topicProposals, setTopicProposals] = useState([]);
    const [alternativeProposals, setAlternativeProposals] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation()

    const getResourceNeighborhood = () => {
        DataService.getResourceNeighborhood(id)
            .then((res) => {
                setResource(res.data.resource);
                setParentResource(res.data.resource.parent);
                setAlternativeProposals(res.data.similarity_proposals)
                setRequirementProposals(res.data.dependency_proposals)
                setConsolidationProposals(res.data.consolidation_proposals)
                setTopicProposals(res.data.topics)
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    useEffect(() => {
        DataService.getProfile()
            .then((res) => {
                if (res.data.resources) setShowModal(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        DataService.getResourceMeta()
            .then((res) => {
                setLanguages(res.data.languages);
                setCategories(res.data.categories);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        if (props.filled) {
            getResourceNeighborhood();
        } else {
            setResource(defaultResource);
        }
    }, [props.filled, id]);

    useEffect(() => {
        setResource(defaultResource);
        setParentResource(null);
        setSearchTerm("");
        setSearchResults(undefined);
        setExtractedContent(undefined);
        setIsKnowledge(false);
        setOverrideChildren(false);
        setPreviewLink("");
        setImage("");
        setExtended(false);
        setShowModal(false);
        setShowURLTutorialModal(false);
        setRequirementProposals([]);
        setConsolidationProposals([]);
        setTopicProposals([]);
        setAlternativeProposals([]);
        setModalShow(false);
    }, [location]);

    const submitResource = () => {
        if (!isUrl(resource.link)) {
            setShowURLTutorialModal(true);
            return;
        }
        let payload = {
            ...resource,
            visited: isKnowledge,
            overrideChildren: overrideChildren,
            category: resource.category == 'Category' ? null : resource.category,
            language: resource.language == 'Language' ? null : resource.language,
        };
        if (parentResource != undefined) {
            payload.parent = parentResource.id;
        } else {
            payload.parent = null;
        }
        if (extractedContent != undefined) {
            payload.content = extractedContent;
            payload.title = extractedContent[0];
        }
        if (props.filled) {
            DataService.updateResourceProposal(id, payload)
                .then((res) => {
                    dispatch(createMessage({ resourceSubmitted: t("resourceEdited") }));
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        } else {
            DataService.addResourceProposal(payload)
                .then((res) => {
                    dispatch(createMessage({ resourceSubmitted: t('submissionSuccessful') }));
                    setResource(defaultResource);
                    setParentResource(null);
                    setSearchResults(undefined);
                    setSearchTerm("");
                    setIsKnowledge(false);
                    setImage("");
                    setExtended(false);
                    setPreviewLink("");
                    setExtractedContent(null);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    const addToKnowledge = () => {
        setIsKnowledge(!isKnowledge);
    }

    const onChange = e => {
        setResource({
            ...resource,
            [e.target.name]: e.target.value
        });
    };

    const toggleFees = e => {
        setResource({
            ...resource,
            fees: !resource.fees
        });
    };

    const submitPreviewLink = e => {
        if (!isUrl(previewLink)) {
            dispatch(returnErrors({ invalidLink: t('invalidLink') }, 500));
            return;
        }
        DataService.getPreview(previewLink)
            .then((res) => {
                setImage(res.data['image'])
                setResource({
                    ...resource,
                    title: res.data['title'],
                    description: res.data['description'],
                    link: previewLink,
                });
                setPreviewLink("");
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getContent = (indexList) => {
        // indexList: [1, 1]
        // extractedContent: ['Title', ['1', ['1.1'], ['1.2']], ['2']]
        // returns: ['1.1']
        let content = extractedContent;
        if (content == undefined) return null;
        indexList.forEach((ix) => {
            content = content[ix];
        });
        return content;
    }

    const updateContent = (indexList, value) => {
        let content = extractedContent.slice();
        let item = content;
        indexList.forEach((ix) => {
            item = item[ix];
        });
        item[0] = value;
        setExtractedContent(content);
    }

    const moveContent = (indexList, movement) => {
        let content = extractedContent.slice();
        let item = content;
        indexList.forEach((ix) => {
            item = item[ix];
        });
        const [movedItem] = item.splice(movement.source.index + 1, 1);
        item.splice(movement.destination.index + 1, 0, movedItem);
        setExtractedContent(content);
    }

    const moveContentStepwise = (indexList, up) => {
        let content = extractedContent.slice();
        let item = content;
        indexList.slice(0, -1).forEach((ix) => {
            item = item[ix];
        });
        const movedIx = indexList[indexList.length - 1]
        const [movedItem] = item.splice(movedIx, 1);
        const newIndex = up ? Math.max(movedIx - 1, 1) : Math.min(movedIx + 1, item.length + 1)
        item.splice(newIndex, 0, movedItem);
        setExtractedContent(content);
    }

    const addContent = (indexList) => {
        let content = extractedContent.slice();
        let item = content;
        indexList.forEach((ix) => {
            item = item[ix];
        });
        item.splice(1, 0, [t('newContent')]);
        setExtractedContent(content);
    }

    const deleteContent = (indexList) => {
        let content = extractedContent.slice();
        let item = content;
        indexList.slice(0, -1).forEach((ix) => {
            item = item[ix];
        });
        item.splice(indexList[indexList.length - 1], 1);
        setExtractedContent(content);
    }

    const contentToComponents = (indexList) => {
        const content = getContent(indexList);
        if (content == undefined) return null;
        const accordionItems = []
        content.slice(1).forEach((_, ix) => {
            const newIndexList = indexList.slice();
            newIndexList.push(ix + 1);
            accordionItems.push(
                <div key={ix}>
                    {contentToComponents(newIndexList)}
                </div>
            );
        });
        return <Accordion
            value={content[0]}
            setValue={(value) => { updateContent(indexList, value) }}
            deleteValue={() => { deleteContent(indexList) }}
            addValue={() => { addContent(indexList) }}
            moveValue={(movement) => { moveContent(indexList, movement) }}
            moveValueStepwise={(up) => { moveContentStepwise(indexList, up) }}
            id={makeid(30)}
            children={accordionItems}
        />;
    }

    const uploadFiles = (e) => {
        DataService.uploadFile(e.target.files[0])
            .then((res) => {
                setExtractedContent(res.data['data']);
            })
            .catch((err) => {
                dispatch(returnErrors({ invalidLink: t(err.response.data) }, 500));
            });
    };

    const languageOptions = [];
    languages.forEach((language) => {
        languageOptions.push(<option value={language} key={language}>{language}</option>)
    });
    languageOptions.push(<option value={'Language'} key={'Language'}>{'Language'}</option>)

    const categoryOptions = [];
    categories.forEach((category) => {
        categoryOptions.push(<option value={category} key={category}>{category}</option>)
    });
    categoryOptions.push(<option value={'Category'} key={'Category'}>{'Category'}</option>)

    const extractedComponents = contentToComponents([]);

    const hiddenFileInput = React.useRef(null);

    const addFile = () => {
        hiddenFileInput.current.click();
    };

    const topicComponents = [];
    topicProposals.forEach((topic) => {
        topicComponents.push(
            <ContentCompactEditDelete
                resource={topic}
                removeResource={(item) => { removeFromNeighborhood(DataService.deleteTopicProposal, item) }}
                link={'topic'}
                key={topic.id}
            />
        );
    });

    const removeFromNeighborhood = (endpoint, item) => {
        endpoint(item.id)
            .then((res) => {
                // TODO: this could get done without a request
                getResourceNeighborhood();
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    let noNeighborhood = <></>;
    if (topicComponents.length == 0 &&
        consolidationProposals.length == 0 &&
        requirementProposals.length == 0 &&
        alternativeProposals.length == 0) {
        noNeighborhood = <div className="text-center"><p>{t('noNeighborhood')}</p></div>
    }

    let neighborhoodSection = <></>
    if (props.filled) {
        neighborhoodSection = <>
            <hr />
            <div className="mb-5 mt-5 align-self-center">
                <h4 className='text-center'>{t('neighborhood')}</h4>
            </div>
            {noNeighborhood}
            {topicComponents.length > 0
                ? <div className="mb-5">
                    <div className="mb-5 align-self-center">
                        <h4 className='text-center'>{t('topics')}</h4>
                    </div>
                    <ComponentTable components={topicComponents} />
                </div>
                : <></>
            }
            {consolidationProposals.length > 0
                ? <EdgeList
                    edges={consolidationProposals}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteEdgeProposal, item) }}
                    relationText={t('deepensUnderstandingOf')} />
                : <></>
            }
            {requirementProposals.length > 0
                ? <EdgeList
                    edges={requirementProposals}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteDependencyEdgeProposal, item) }}
                    relationText={t('requiredToUnderstand')} />
                : <></>
            }
            {alternativeProposals.length > 0
                ? <EdgeList
                    edges={alternativeProposals}
                    alternative={true}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteSimilarityProposal, item) }}
                    relationText={t('isAlternative')} />
                : <></>
            }
        </>
    }

    if ((resource.link == "") && (extractedComponents == undefined)) {
        return (
            <>
                <h4 className="text-center mb-5">{t('addResource')}</h4>
                <div className="row">
                    <div className="col-6 text-center">
                        <img src={linkImage} className="img-fluid" style={{ height: '400px', width: '400px' }}></img>
                    </div>
                    <div className="col-6 text-center mt-3">
                        <img src={pdfImage} className="img-fluid" style={{ height: '350px', width: '350px' }}></img>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='mx-auto w-75 mb-3'>
                            <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                placeholder="Link"
                                name="previewLink"
                                value={previewLink}
                                onChange={(e) => { setPreviewLink(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="text-center">
                            <button className="btn primary" onClick={submitPreviewLink}>
                                {t('submitLink')}
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="text-center">
                            <button className="btn primary" onClick={addFile}>
                                {t('uploadPDF')}
                            </button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={uploadFiles}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="text-center">
                        <a href="https://storyset.com/">Illustrations by Storyset</a>
                    </div>
                </div>
                <Offcanvas show={showModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <ResourcesTutorial setShow={setShowModal} />
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    } else {
        return (
            <>
                <h4 className="text-center">{props.filled ? "Edit Resource" : "Add Resource"}</h4>
                {
                    resource.approved
                        ? <p className="text-center mt-3 mb-5">
                            {t('approvedResourceEdit')}
                        </p>
                        : <div className="mt-5"></div>
                }
                <div className='mx-auto w-75 mb-3'>
                    <input
                        type="text"
                        className="form-control form-control-lg mb-3"
                        placeholder={t('link')}
                        name="link"
                        value={resource.link}
                        onChange={onChange}
                    />
                    {
                        image != ""
                            ? <div className="row my-5">
                                <div className="col-6">
                                    <img src={image} alt="..." className="img-thumbnail"></img>
                                </div>
                            </div>
                            : <></>
                    }
                    {extractedComponents == undefined
                        ?
                        <>
                            <h5>{t('title')}</h5>
                            <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                placeholder={t('title')}
                                name="title"
                                value={resource.title}
                                onChange={onChange}
                            />
                        </>
                        :
                        <>
                            <h5 className="mt-5">{t('extractedStructure')}</h5>
                            <div className="mb-5">
                                {extractedComponents}
                            </div>
                        </>
                    }
                    <h5>{t('description')}</h5>
                    <textarea
                        type="text"
                        className="form-control mb-5"
                        rows="3"
                        placeholder={t('descriptionPlaceholder')}
                        name="description"
                        value={resource.description}
                        onChange={onChange}
                    />
                    {
                        props.filled
                            ?
                            <div className="text-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={overrideChildren}
                                    onChange={() => { setOverrideChildren(!overrideChildren) }}
                                />
                                <label className="form-check-label" onClick={() => { setOverrideChildren(!overrideChildren) }}>{t('overrideChildren')}</label>
                            </div>
                            :
                            <div className="text-center">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isKnowledge}
                                    onChange={addToKnowledge}
                                />
                                <label className="form-check-label" onClick={addToKnowledge}>{t('addToKnowledge')}</label>
                            </div>
                    }
                    <>
                        <h5 className="text-center mb-3 mt-5">{t('parentResource')}</h5>
                        <div className="my-3">
                            {parentResource == undefined
                                ? <div className="text-center mt-3 mb-5">
                                    <button className="btn btn-light" onClick={() => { setModalShow(true) }}>
                                        {t('addParent')}
                                    </button>
                                </div>
                                : <div className="mb-5"><ResourceCompactSelect
                                    resource={parentResource}
                                    isSelected={true}
                                    deselect={() => { setParentResource(null) }}
                                    deselectText={"Remove"}
                                /></div>
                            }
                        </div>
                    </>
                    {extended
                        ?
                        <><h5>{t('author')}</h5>
                            <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                placeholder={t('author')}
                                name="author"
                                value={resource.author}
                                onChange={onChange}
                            />
                            <h5>{t('duration')}</h5>
                            <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                placeholder={t('duration')}
                                name="duration"
                                value={resource.duration}
                                onChange={onChange}
                            />
                            <h5>{t('category')}</h5>
                            <select
                                className="custom-select custom-select-lg mb-3"
                                name="category"
                                value={resource.category}
                                onChange={onChange}>
                                {categoryOptions}
                            </select>
                            <h5>{t('language')}</h5>
                            <select
                                className="custom-select custom-select-lg mb-3"
                                name="language"
                                value={resource.language}
                                onChange={onChange}>
                                {languageOptions}
                            </select>
                            <div className="text-center mb-5">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={resource.fees}
                                    onChange={toggleFees}
                                    name="fees"
                                />
                                <label className="form-check-label" onClick={toggleFees}>{t('freeAccess')}</label>
                            </div>
                        </>
                        :
                        <div className="text-center">
                            <button className="btn btn-light" onClick={() => { setExtended(true); }}>{t('more')}</button>
                        </div>
                    }
                    <div className="text-center mt-5">
                        {
                            resource.approved
                                ?
                                <></>
                                :
                                <button className="btn primary mb-5" onClick={submitResource}>
                                    {props.filled ? t('update') : t('submit')}
                                </button>
                        }
                    </div>
                </div>
                {neighborhoodSection}
                <br />
                <ModalBody
                    resource={
                        <>
                            <h4 className="text-center mb-3 mt-2">{t('searchParent')}</h4>
                            <ResourceSearchSelect
                                selectedId={parentResource == undefined ? null : parentResource.id}
                                setSelected={(resource) => { setParentResource(resource); setModalShow(false); }}
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                        </>
                    }
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <Offcanvas show={showURLTutorialModal} onHide={() => { setShowURLTutorialModal(false) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <URLTutorial setShow={setShowURLTutorialModal} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={showModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <ResourcesTutorial setShow={setShowModal} />
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
};


export const AddTopic = (props) => {
    const defaultTopic = {
        title: "",
        description: "",
    }

    const { t, i18n } = useTranslation();
    const [selectedResources, setSelectedResources] = useState([]);
    const [searchResults, setSearchResults] = useState(undefined);
    const [topic, setTopic] = useState(defaultTopic);
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation()

    useEffect(() => {
        DataService.getProfile()
            .then((res) => {
                if (res.data.topics) setShowModal(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        if (props.filled) {
            DataService.getTopicProposal(id)
                .then((res) => {
                    const selectedResources = res.data.resources.slice();
                    selectedResources.push(...res.data.topics);
                    setSelectedResources(selectedResources);
                    setTopic(res.data);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        } else {
            setTopic(defaultTopic);
        }
    }, [props.filled]);

    useEffect(() => {
        setSearchResults(undefined);
        setSearchTerm("");
        setSelectedResources([]);
        setShowModal(false);
        setModalShow(false);
        setTopic(defaultTopic);
    }, [location]);

    const submitTopic = () => {
        const resourceIds = [];
        const topicIds = [];
        selectedResources.forEach((selectedResource) => {
            if ('identifier' in selectedResource) {
                topicIds.push(selectedResource.id);
            } else {
                resourceIds.push(selectedResource.id);
            }
        });

        let payload = {
            ...topic,
            resources: resourceIds,
            topics: topicIds,
        };

        if (props.filled) {
            DataService.updateTopicProposal(id, payload)
                .then((res) => {
                    dispatch(createMessage({ resourceSubmitted: t('topicEdited') }));
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        } else {
            DataService.addTopicProposal(payload)
                .then((res) => {
                    dispatch(createMessage({ resourceSubmitted: t('submissionSuccessful') }));
                    setTopic(defaultTopic);
                    setSelectedResources([]);
                    setSearchResults(null);
                    setSearchTerm("");
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    const onChange = e => {
        setTopic({
            ...topic,
            [e.target.name]: e.target.value
        });
    };

    const deselect = (resource) => {
        const newSelectedResources = [];
        selectedResources.forEach((selectedResource) => {
            if (selectedResource.id !== resource.id) {
                newSelectedResources.push(selectedResource);
            }
        });
        setSelectedResources(newSelectedResources);
    }

    const selectedComponents = [];
    selectedResources.forEach((selectedResource) => {
        selectedComponents.push(
            <Card
                resource={
                    <ResourceCompact resource={selectedResource} key={selectedResource.id} />
                }
                footer={
                    <Select
                        select={() => { }}
                        deselect={deselect}
                        item={selectedResource}
                        isSelected={true}
                        selectText={""}
                        deselectText={t('deselect')}
                    />
                }
            />
        )
    });

    return (
        <>
            <h4 className="text-center">{props.filled ? t('editTopic') : t('addTopic')}</h4>
            {
                topic.approved
                    ? <p className="text-center mt-3 mb-5">
                        {t('approvedTopicEdit')}
                    </p>
                    : <div className="mt-5"></div>
            }
            <div className='mx-auto w-75 mb-3'>
                <input
                    type="text"
                    className="form-control form-control-lg mb-3"
                    placeholder={t('title')}
                    name="title"
                    value={topic.title}
                    onChange={onChange}
                />
                <textarea
                    type="text"
                    className="form-control mb-3"
                    rows="3"
                    placeholder={(t('description'))}
                    name="description"
                    value={topic.description}
                    onChange={onChange}
                />

                <p className="text-center mb-3 mt-5"><strong>{t('content')}</strong></p>
                <div className="my-3">
                    {selectedComponents.length == 0
                        ? <></>
                        : <ComponentTable components={selectedComponents} />
                    }
                </div>

                <div className="text-center mt-3 mb-5">
                    <button className="btn btn-light" onClick={() => { setModalShow(true) }}>
                        {t('addContent')}
                    </button>
                </div>

                <div className="text-center mt-5">
                    {
                        topic.approved
                            ?
                            <></>
                            :
                            <button className="btn primary mb-5" onClick={submitTopic}>
                                {props.filled ? t('update') : t('submit')}
                            </button>
                    }
                </div>
            </div>
            <Offcanvas show={showModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                <Offcanvas.Body>
                    <TopicsTutorial setShow={setShowModal} />
                </Offcanvas.Body>
            </Offcanvas>
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('searchContent')}</h4>
                        <ResourceSearchMultiSelect
                            selectedResources={selectedResources}
                            setSelectedResources={setSelectedResources}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            includeTopics={true}
                        />
                    </>
                }
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};
