import {
    CLEAR_SEARCH_RESULTS,
    SEARCH,
    GET_GOALS, 
    ADD_GOAL, 
    DELETE_GOAL, 
    GET_GOAL, 
    GET_RECOMMENDATIONS, 
    GET_PATH, 
    VISIT_RECOMMENDATION, 
    CHANGE_QUALITY_SLIDER, 
    CHANGE_UNDERSTANDING_SLIDER
} from '../actions/types';

const initialState = {
    searchResults: null,
    goals: [],
    currentGoal: {
        id: null,
        resource_id: "",
        approved: false,
        parent: null,
        title: "",
        link: "",
        ranking: null,
    },
    currentPath: [],
    currentRecommendations: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH:
            return {
                ...state,
                searchResults: payload,
            };
        case CLEAR_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: null,
            };
        case GET_GOALS:
            return {
                ...state,
                goals: payload,
            }
        case GET_GOAL:
            return {
                ...state,
                currentGoal: payload,
            }
        case GET_PATH:
            return {
                ...state,
                currentPath: payload,
            }
        case GET_RECOMMENDATIONS:
            return {
                ...state,
                currentRecommendations: payload,
            }
        case CHANGE_QUALITY_SLIDER:
        case CHANGE_UNDERSTANDING_SLIDER:
            const newPath = [];
            state.currentPath.forEach((userEdge) => {
                if (userEdge.edge.id === payload.edge.id) {
                    newPath.push(payload);
                } else {
                    newPath.push(userEdge);
                }
            });
            return {
                ...state,
                currentPath: newPath,
            }
        case VISIT_RECOMMENDATION:
        case ADD_GOAL:
        case DELETE_GOAL:
        default:
            return state;
    }
}