import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { returnErrors, createMessage } from "../../actions/messages";
import { Card, ComponentTable, ItemCarousel, ModalBody } from "./Utils";
import { ResourceCompact } from "./Resources";
import DataService from '../../services/DataService';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { PathSegment } from "./Paths";
import { ResourceCompactSelect } from "./ResourceUtils";
import '../../languages/i18n';


export const RecommendationsSection = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const completeGoal = () => {
        DataService.completeGoal(props.goal.id)
            .then((res) => {
                dispatch(createMessage({ goalCompleted: t('goalCompleted') }));
                const updatedGoal = {
                    ...props.goal,
                    completed: true
                }
                props.setGoal(updatedGoal);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const reactivateGoal = () => {
        DataService.activateGoal(props.goal.id)
            .then((res) => {
                dispatch(createMessage({ goalReactivated: t('goalReactivated') }));
                const updatedGoal = {
                    ...props.goal,
                    completed: false
                }
                props.setGoal(updatedGoal);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    let recommendationSection = <></>
    if (props.recommendations === null) {
        recommendationSection = <div className="text-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('loading')}</span>
            </div>
        </div>
    } else {
        // resources array is used to determine the path dots
        const resources = [];
        const recommendations = [];

        props.externalPath.forEach((resource) => {
            resources.push(null);
            recommendations.push([
                <Card
                    resource={<ResourceCompact resource={resource} />}
                    key={resource.id}
                />
            ])
        });

        props.path.forEach((userEdge) => {
            resources.push(null);
            recommendations.push([
                <PathSegment
                    userEdge={userEdge}
                    path={props.path}
                    updatePath={props.updatePath}
                    goalId={props.goal.id}
                />
            ]);
        });

        props.recommendations.forEach((recommendationGroup, ix) => {
            resources.push(recommendationGroup);
            const recommendationComponents = [];
            recommendationGroup.resources.forEach((recommendation, groupIx) => {
                recommendationComponents.push(
                    <Recommendation
                        resource={recommendation}
                        position={props.path.length}
                        goal={props.goal}
                        step={ix}
                        setCompletedResource={props.setCompletedResource}
                        setRecommendations={props.setRecommendations}
                        updatePath={props.updatePath}
                        expanded={recommendationGroup.expanded.includes(groupIx)}
                        key={recommendation.id}
                    />
                );
            });
            recommendations.push(recommendationComponents);
        });

        // if resources consist of only completed ones, show goal completion
        if (resources[resources.length - 1] !== null) {
            if (props.goal.completed) {
                resources.push(null);
                recommendations.push([
                    <div className="text-center">
                        <p className='text-center mt-3 mb-3'>{t('recommendationsAvailable')}</p>
                        <button className='btn primary btn-lg mb-5' onClick={reactivateGoal}>{t('reactivate')}</button>
                    </div>
                ]);
            }
        } else {
            if (props.goal.completed) {
                resources.push(null);
                recommendations.push([
                    <div className="text-center">
                        <p className='text-center mt-3 mb-3'>{t('noRecommendationsAvailable')}</p>
                        <button className='btn primary btn-lg mb-5'>{t('completed')}</button>
                    </div>
                ]);
            } else {
                resources.push(null);
                recommendations.push([
                    <div className="text-center">
                        <p className='text-center mt-3 mb-3'>{t('noRecommendationsAvailable')}</p>
                        <button className='btn primary btn-lg mb-5' onClick={completeGoal}>{t('markCompleted')}</button>
                    </div>
                ]);
            }
        }

        recommendationSection =
            <ItemCarousel
                components={recommendations}
                resources={resources}
                completedLength={props.path.length}
                externalLength={props.externalPath.length}
                completedResource={props.completedResource}
                id={"recommendations"}
                updatePath={props.updatePath}
                goal={props.goal}
            />
    }

    return (
        <>
            <div className="mb-5">
                {recommendationSection}
            </div>
        </>
    );
};


export const Recommendation = (props) => {
    const dispatch = useDispatch();
    const [completionLoading, setCompletionLoading] = useState(false);
    const [skipLoading, setSkipLoading] = useState(false);
    const [expansionLoading, setExpansionLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const completeResource = () => {
        setCompletionLoading(true);
        DataService.addEdge(props.goal.id, props.resource.id, props.position)
            .then((res) => {
                props.updatePath(props.goal.id, props.resource, true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const skipStep = () => {
        setSkipLoading(true);
        // add first resource of recommendation group to implicit knowledge
        DataService.addKnowledge(props.resource.id)
            .then((res) => {
                props.updatePath(props.goal.id);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const expandRecommendation = () => {
        setExpansionLoading(true);
        DataService.expandRecommendation(props.goal.id, props.resource.id)
            .then((res) => {
                props.setCompletedResource(false);
                props.setRecommendations(res.data.recommendations);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const resource = { ...props.resource }

    const noInterestTooltip = (props) => (
        <Tooltip id="no-interest-tooltip" {...props}>
            {t('skip')}
        </Tooltip>
    );

    const requirementsTooltip = (props) => (
        <Tooltip id="requirements-tooltip" {...props}>
            {t('expand')}
        </Tooltip>
    );

    let expansionButton = <></>;
    if (!props.expanded) {
        expansionButton = <>{expansionLoading
            ? <div className="spinner-border" role="status">
                <span className="visually-hidden">{t('loading')}</span>
            </div>
            :
            <OverlayTrigger
                placement="top"
                delay={{ show: 0, hide: 0 }}
                overlay={requirementsTooltip}
            >
                <button
                    className="btn btn-light secondary w-100"
                    onClick={expandRecommendation}>
                    <i className="fa-solid fa-plus"></i>
                </button>
            </OverlayTrigger>
        }</>
    }

    return (
        <>
            <Card
                resource={<ResourceCompact resource={resource} />}
                footer={
                    <div className="row">
                        <div className="col-8">
                            {completionLoading
                                ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">{t('loading')}</span>
                                </div>
                                :
                                <button className="btn primary w-100" onClick={completeResource}>{t('complete')}</button>
                            }
                        </div>
                        <div className="col-2">
                            {skipLoading
                                ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">{t('loading')}</span>
                                </div>
                                :
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={noInterestTooltip}
                                >
                                    <button className="btn btn-light secondary w-100" onClick={skipStep}><i className="fa-solid fa-ban"></i></button>
                                </OverlayTrigger>
                            }
                        </div>
                        <div className="col-2">
                            {expansionButton}
                        </div>
                    </div>
                }
            />
        </>
    );
};

