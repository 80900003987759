import { combineReducers } from 'redux';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import paths from './paths';

export default combineReducers({
    errors,
    messages,
    auth,
    paths
});