import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { RecommendationsSection } from "./Recommendations";
import { Card, ComponentTable, ButtonRating, BinaryRating, ModalBody } from "./Utils";
import { ResourceCompact, ContentTitle, ContentIcons, ResourceMinimal } from "./Resources";
import { returnErrors } from "../../actions/messages";
import { Link } from 'react-router-dom';
import { ResourceCompactSelect } from "./ResourceUtils";
import DataService from "../../services/DataService";
import { PathTutorial } from "./Tutorial";
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useTranslation } from 'react-i18next';
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import '../../languages/i18n';


export const Path = () => {
    const dispatch = useDispatch();
    const [goal, setGoal] = useState({ id: null, resource: { title: "", link: "" }, completed: false });
    const [path, setPath] = useState([]);
    const [recommendations, setRecommendations] = useState(null);
    const [consolidationComponents, setConsolidationComponents] = useState([])
    const [externalPath, setExternalPath] = useState([])
    const [resourceForConsolidations, setResourceForConsolidations] = useState({ title: "", link: "" })
    const [showConsolidationModal, setShowConsolidationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [completedResource, setCompletedResource] = useState(false);
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        updatePath(id);
        DataService.getProfile()
            .then((res) => {
                if (res.data.path) setShowModal(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }, []);

    const completeResource = (resource, consolidationTarget) => {
        DataService.addEdge(goal.id, resource.id, path.length + 1, consolidationTarget.id)
            .then((res) => {
                updatePath(goal.id, null, true);
                setShowConsolidationModal(false);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const updatePath = (goalId, resourceForConsolidations, completedResource) => {
        if (completedResource) {
            setCompletedResource(true);
        } else {
            setCompletedResource(false);
        }
        DataService.getGoal(goalId)
            .then((res) => {
                setGoal(res.data);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        DataService.getEdges(goalId)
            .then((res) => {
                setPath(res.data);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        DataService.getRecommendations(goalId)
            .then((res) => {
                setExternalPath(res.data.completed_resources);
                setRecommendations(res.data.recommendations);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        if (resourceForConsolidations) {
            setResourceForConsolidations(resourceForConsolidations);
            DataService.getConsolidations(resourceForConsolidations.id)
                .then((res) => {
                    const resourceComponents = [];
                    res.data.forEach((resource) => {
                        resourceComponents.push(
                            <ResourceCompactSelect
                                selectText={t('complete')}
                                select={(resource) => { completeResource(resource, resourceForConsolidations); }}
                                resource={resource}
                            />
                        )
                    });
                    setConsolidationComponents(resourceComponents);
                    if (resourceComponents.length > 0) setShowConsolidationModal(true);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    }

    return (
        <>
            <div className="mb-5">
                <h2 className='text-center mb-5'>{t('learningPathFor')}</h2>
                <div className="row">
                    <div className="col-4 offset-md-4">
                        <Card
                            resource={
                                <ResourceMinimal
                                    resource={goal.resource ? goal.resource : goal.topic}
                                    done={path.length}
                                    todo={recommendations ? recommendations.length : 0}
                                />
                            }
                        />
                    </div>
                </div>
                <div className='text-center mb-5'></div>
                <Card
                    resource={
                        <RecommendationsSection
                            recommendations={recommendations}
                            goal={goal}
                            setGoal={setGoal}
                            path={path}
                            externalPath={externalPath}
                            updatePath={updatePath}
                            completedResource={completedResource}
                            setCompletedResource={setCompletedResource}
                            setRecommendations={setRecommendations} />
                    }
                />
            </div>
            <Offcanvas show={showModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                <Offcanvas.Body>
                    <PathTutorial setShow={setShowModal} />
                </Offcanvas.Body>
            </Offcanvas>
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3">{t('consolidationsAfterCompleteCongrats')}</h4>
                        <h4 className="text-center mb-3"><a href={resourceForConsolidations.link}>{resourceForConsolidations.title}</a></h4>
                        <h4 className="text-center mb-5">{t('consolidationsAfterCompleteText')}</h4>
                        {consolidationComponents.length > 0
                            ? <ComponentTable components={consolidationComponents} />
                            : <p className="text-center">{t('noConsolidationRecommendations')}</p>
                        }
                    </>
                }
                show={showConsolidationModal}
                onHide={() => setShowConsolidationModal(false)}
            />
            <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </>
    );
};


export const PathSegment = (props) => {
    const [dependencies, setDependencies] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        DataService.getDependencies(props.goalId, props.userEdge.step)
            .then((res) => {
                setDependencies(res.data);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }, []);

    const removeResource = () => {
        DataService.deleteEdge(props.userEdge.id)
            .then((res) => {
                // TODO: This should work without a request
                props.updatePath(props.goalId);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const updateDependencyRating = (id, rating) => {
        let updatedDependencies = dependencies.slice();
        updatedDependencies.forEach((dependency) => {
            if (dependency.edge.id === id) {
                dependency.rating = rating;
            }
        });
        setDependencies(updatedDependencies);
    }

    const dependencyElements = [];
    dependencies.forEach((dependency) => {
        dependencyElements.push(
            <Dependency
                dependency={dependency}
                updateDependencyRating={updateDependencyRating}
                key={dependency.edge.id}
            />
        );
    });

    return (
        <Card
            resource={
                <PathResource
                    goalId={props.goalId}
                    userEdge={props.userEdge}
                    removeResource={removeResource}
                    path={props.path}
                />
            }
            footer={
                <PathDependencies
                    dependencies={dependencyElements}
                    resource={props.userEdge.edge.destination}
                    updatePath={props.updatePath}
                    goalId={props.goalId}
                    path={props.path}
                />
            }
        />
    );
};


export const PathResource = (props) => {
    const resource = { ...props.userEdge.edge.destination }
    const { t, i18n } = useTranslation();

    const trashTooltip = (props) => (
        <Tooltip id="trash-tooltip" {...props}>
            {t('undo')}
        </Tooltip>
    );

    return (
        <>
            <div className="row">
                <div className="col-11">
                    <ContentTitle title={resource.title} link={resource.link} maxLength={100} />
                    <ContentIcons resource={resource} />
                </div>
                <div className="col-1 text-end">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 0, hide: 0 }}
                        overlay={trashTooltip}
                    >
                        <button className='btn btn-light btn-sm' onClick={props.removeResource}><i className="fa-solid fa-trash-can"></i></button>
                    </OverlayTrigger>
                </div>
            </div>
            <h6 className="text-center mt-3 mb-3">{t('understandabilityQuestion')}</h6>
            <div className="row mb-2 mt-2">
                <div className="col-6 offset-md-3">
                    <ButtonRating
                        rating={props.userEdge.understanding_rating}
                        endpoint={(payload) => { return DataService.rateEdge(props.goalId, props.userEdge.step, payload) }}
                        payloadBuilder={(rating) => { return { understanding_rating: rating }; }}
                    />
                </div>
            </div>
            <h6 className="text-center mt-4 mb-3">{t('likeabilityQuestion')}</h6>
            <BinaryRating
                light={true}
                rating={props.userEdge.explanation_rating}
                endpoint={(payload) => { return DataService.rateEdge(props.goalId, props.userEdge.step, payload) }}
                payloadBuilder={(rating) => { return { explanation_rating: rating }; }}
            />
            <div className="mb-3" />
        </>
    );
}


export const PathDependencies = (props) => {
    const [consolidationComponents, setConsolidationComponents] = useState([])
    const [showConsolidationModal, setShowConsolidationModal] = useState(false);
    const [showRequirementModal, setShowRequirementModal] = useState(false);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const completeResource = (resource) => {
        DataService.addEdge(props.goalId, resource.id, props.path.length, props.resource.id)
            .then((res) => {
                props.updatePath(props.goalId);
                setShowConsolidationModal(false);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const getConsolidations = () => {
        DataService.getConsolidations(props.resource.id)
            .then((res) => {
                const resourceComponents = [];
                res.data.forEach((resource) => {
                    resourceComponents.push(
                        <ResourceCompactSelect
                            selectText={t('complete')}
                            select={completeResource}
                            resource={resource}
                        />
                    )
                });
                setConsolidationComponents(resourceComponents);
                setShowConsolidationModal(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <button
                        className='btn btn-light w-100'
                        onClick={() => { setShowRequirementModal(true) }}>
                        {t('requirements')}
                        {
                            props.dependencies.length > 0
                                ? <>&ensp;<span className="badge bg-dark">{props.dependencies.length}</span></>
                                : <></>
                        }
                    </button>
                </div>
                <div className="col-6">
                    <button
                        className="btn btn-light w-100"
                        onClick={getConsolidations}>
                        {t('deepenUnderstanding')}
                    </button>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <Link to={"/addAlternative/" + props.resource.id} className="btn btn-light w-100">{t('suggestAlternative')}</Link>
                </div>
            </div>
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3">{t('consolidationRecommendations')}</h4>
                        <h4 className="text-center mb-5"><a href={props.resource.link}>{props.resource.title}</a></h4>
                        {consolidationComponents.length > 0
                            ? <ComponentTable components={consolidationComponents} />
                            : <p className="text-center">{t('noConsolidationRecommendations')}</p>
                        }
                        <div className="text-center">
                            <Link to={"/addConsolidation/" + props.resource.id} className="btn primary">{t('suggestConsolidation')}</Link>
                        </div>
                    </>
                }
                show={showConsolidationModal}
                onHide={() => setShowConsolidationModal(false)}
            />
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3">{t('requirementExplanation')}</h4>
                        <h4 className="text-center mb-5"><a href={props.resource.link}>{props.resource.title}</a></h4>
                        {props.dependencies.length > 0
                            ? <ComponentTable components={props.dependencies} />
                            : <p className="text-center">{t('noRequirementsNecessary')}</p>
                        }
                        <div className="text-center">
                            <Link to={"/addRelation/" + props.resource.id} className="btn primary">{t('suggestRequirement')}</Link>
                        </div>
                    </>
                }
                show={showRequirementModal}
                onHide={() => setShowRequirementModal(false)}
            />
        </>
    );
}


export const Dependency = (props) => {
    const { t, i18n } = useTranslation();

    const endpoint = (payload) => {
        props.updateDependencyRating(props.dependency.edge.id, payload.rating);
        return DataService.rateDependency(props.dependency.edge.destination.id, props.dependency.edge.source.id, payload);
    }

    return (
        <Card
            resource={<ResourceCompact resource={props.dependency.edge.destination} />}
            footer={
                <>
                    <p className="text-center">{t('necessityQuestion')}</p>
                    <div className="row">
                        <div className="col">
                            <BinaryRating
                                rating={props.dependency.rating}
                                endpoint={endpoint}
                                payloadBuilder={(rating) => { return { rating: rating }; }}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <Link to={"/addAlternative/" + props.dependency.edge.destination.id} className="btn w-100 btn-light">{t('suggestAlternative')}</Link>
                        </div>
                    </div>
                </>
            }
        />
    );
}
