import React, { useState, useEffect, useRef } from "react";
import '../../css/Carousel.css';
import '../../css/Voting.css';
import { useDispatch } from "react-redux";
import { returnErrors, createMessage } from "../../actions/messages";
import Modal from 'react-bootstrap/Modal'
import { useLocation } from "react-router";
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ResourceSearchSelect } from "./ResourceUtils";
import DataService from "../../services/DataService";
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';


export const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <iframe
            width="500"
            height="281"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Tutorial"
        />
    </div>
);


export const Carousel = (props) => {
    const carouselItems = [];
    const carouselIndicators = [];
    let itemGroup = [];
    props.components.forEach((item, ix) => {
        if (ix > 0 && ix % 3 == 0) {
            carouselItems.push(
                <div className={carouselItems.length == 0 ? "carousel-item c-item active" : "carousel-item c-item"} key={ix}>
                    <div className="row h-100">
                        {itemGroup}
                    </div>
                </div>
            );
            carouselIndicators.push(
                <li
                    data-bs-target={'#' + props.id}
                    data-bs-slide-to={ix / 3 - 1}
                    // inactive in both cases is necessary for style to not get overwritten by bootstrap
                    className={carouselItems.length == 1 ? "active inactive" : "inactive"}
                    key={ix / 3 - 1}
                ></li>
            )
            itemGroup = [
                <div className="col-md-4 mb-3" key={0}>
                    {item}
                </div>
            ];
        } else {
            itemGroup.push(
                <div className="col-md-4 mb-3" key={ix % 3}>
                    {item}
                </div>
            );
        }
    });
    carouselItems.push(
        <div className={carouselItems.length == 0 ? "carousel-item c-item active" : "carousel-item c-item"} key={carouselItems.length * 3 + 3}>
            <div className="row h-100">
                {itemGroup}
            </div>
        </div>
    );
    carouselIndicators.push(
        <li
            data-bs-target={'#' + props.id}
            data-bs-slide-to={carouselIndicators.length}
            // inactive in both cases is necessary for style to not get overwritten by bootstrap
            className={carouselItems.length == 1 ? "active inactive" : "inactive"}
            key={carouselIndicators.length}
        ></li>
    )

    return (
        <div className="row">
            <div className="col-6">
                <h4 className="mb-5">{props.title}</h4>
            </div>
            {carouselItems.length > 1
                ? <div className="col-6 text-end">
                    <a className="btn btn-light mb-3 me-1" href={'#' + props.id} role="button" data-bs-slide="prev">
                        <i className="fa fa-arrow-left"></i>
                    </a>
                    <a className="btn btn-light mb-3 " href={'#' + props.id} role="button" data-bs-slide="next">
                        <i className="fa fa-arrow-right"></i>
                    </a>
                </div>
                : <></>}
            <div className="col-12 mt-3">
                <div id={props.id} className="carousel" data-bs-interval="false">
                    {carouselItems.length > 1
                        ? <ol className="carousel-indicators c-indicators">
                            {carouselIndicators}
                        </ol>
                        : <></>
                    }
                    <div className="carousel-inner c-inner">
                        {carouselItems}
                    </div>
                </div>
            </div>
        </div>
    );
}


export const ItemCarousel = (props) => {
    const [carouselItems, setCarouselItems] = useState([]);
    const [carouselIndicators, setCarouselIndicators] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [position, setPosition] = useState(null);
    const [searchResults, setSearchResults] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let newActiveIndex = props.completedLength + props.externalLength;
        if (props.completedLength > 0 && props.completedResource) {
            newActiveIndex -= 1;
        }
        updateCarouselItems(newActiveIndex);
        // set active index to first recommendation
        setActiveIndex(newActiveIndex);
    }, [props.components]);

    const updateCarouselItems = (activeIx) => {
        // caluclated sizes and normalized sizes using the dependency information for each recommendation
        const sizes = []
        const normedSizes = []
        let completedEndIndex = 0;
        props.resources.forEach((resource) => {
            // calculate sizes only based on the recommendations, ignore completed resources
            if (resource !== null) {
                const dependencies = resource.dependencies;
                let size = 1;
                size += dependencies.length
                dependencies.forEach((dependencyIndex) => {
                    // dependencies not necessarily come before the resource which depends on them.
                    if (dependencyIndex < sizes.length) {
                        size += sizes[dependencyIndex];
                    }
                });
                sizes.push(size);
            } else {
                completedEndIndex++;
                normedSizes.unshift(null);
            }
        });

        const minSize = Math.min(...sizes);
        const maxSize = Math.max(...sizes);
        sizes.forEach((size) => {
            // normalize dot sizes to a range between 10 and 25 with 4 different dot sizes (10, 15, 20, 25)
            normedSizes.push(Math.ceil((((size - minSize) / Math.max((maxSize - minSize), 1)) * 15 + 10) / 5) * 5);
        });

        const indexGroups = [];
        // collect indices in such a manner that it can be shortened by leaving out the smaller
        // items of the learning path. This code section builds indexGroups like e.g.
        // [[0], [1], [2], [3], [4], [5, 6, 7], [8], [9, 10, 11, 12], [13], [14, 15], [16]]
        // where single item lists get their own dot in the recommendations, but multi-item
        // lists get summarized in a single line (alternating with dots)
        if (normedSizes.length > 21) {
            const remainder = normedSizes.slice(-(normedSizes.length - 5));
            const largestSizes = remainder.sort(function (a, b) { return a - b; }).slice(-5);
            let currentIndexGroup = [];
            normedSizes.forEach((item, ix) => {
                if (item === null) {
                    if (completedEndIndex > 5) {
                        if (ix === 0) {
                            indexGroups.push([ix]);
                        } else if (ix === completedEndIndex - 5) {
                            indexGroups.push(currentIndexGroup);
                            currentIndexGroup = [];
                            indexGroups.push([ix]);
                        } else if (ix > completedEndIndex - 5) {
                            indexGroups.push([ix]);
                        } else {
                            currentIndexGroup.push(ix);
                        }
                    } else {
                        indexGroups.push([ix]);
                    }
                }
                if (ix >= completedEndIndex) {
                    if (normedSizes.length - completedEndIndex > 21) {
                        if (ix < completedEndIndex + 5) {
                            // always keep the first five items of learning path
                            indexGroups.push([ix]);
                        } else {
                            // filter the largest items from the remainder of the learning path
                            if (largestSizes.includes(item) || ix == normedSizes.length - 1) {
                                if (currentIndexGroup.length > 0) {
                                    indexGroups.push(currentIndexGroup);
                                }
                                indexGroups.push([ix]);
                                largestSizes.splice(largestSizes.indexOf(item), 1);
                                currentIndexGroup = [];
                            } else {
                                currentIndexGroup.push(ix);
                            }
                        }
                    } else {
                        indexGroups.push([ix]);
                    }
                }
            });
        } else {
            // the learning path is small enough to show all dots
            normedSizes.forEach((item, ix) => {
                indexGroups.push([ix]);
            });
        }

        const carouselIndicatorsInitial = [];
        indexGroups.forEach((indexGroup) => {
            if (indexGroup.length == 1) {
                const size = normedSizes[indexGroup[0]];
                const color = size === null ? "#444444" : (size > 20 ? "#ffc000" : "#41527f");
                carouselIndicatorsInitial.push(
                    <li
                        data-bs-target={'#' + props.id}
                        style={{ "width": "25px", "height": "25px", "borderRadius": "100%", "backgroundColor": color }}
                        // inactive in both cases is necessary for style to not get overwritten by bootstrap
                        className={indexGroup.includes(activeIx) ? "active inactive align-self-center" : "inactive align-self-center"}
                        onClick={() => jumpToIndex(indexGroup[0])}
                        key={indexGroup[0]}
                    />
                );
            } else {
                let color = "#41527f";
                if (normedSizes[indexGroup[0]] === null) {
                    color = '#444444';
                }
                carouselIndicatorsInitial.push(
                    <li
                        data-bs-target={'#' + props.id}
                        style={{ "backgroundColor": color }}
                        // inactive in both cases is necessary for style to not get overwritten by bootstrap
                        className={indexGroup.includes(activeIx) ? "active inactive align-self-center" : "inactive align-self-center"}
                        onClick={() => jumpToIndex(indexGroup[0])}
                        key={indexGroup[0]}
                    />
                );
            }
        });

        const carouselItemsInitial = [];
        props.components.forEach((items, ix) => {
            const itemComponents = [];
            // define grid layout for recommendations. There is a maximum of 3, minimum of 1
            let columnClass = "col-" + (12 / items.length) + ' mb-3';
            if (items.length === 1) columnClass = "col-4 offset-md-4 mb-3";
            if (items.length === 2) columnClass = "col-4 mb-3";
            if (normedSizes[ix] === null) columnClass = "col-6 offset-md-3 mb-3";
            items.forEach((item, ix) => {
                itemComponents.push(
                    <div className={columnClass + (items.length === 2 ? (ix === 0 ? " offset-md-2" : "") : "")} key={'col' + item.key}>
                        {item}
                    </div>
                );
            });
            carouselItemsInitial.push(
                <div className={ix === activeIx ? "carousel-item ic-item active" : "carousel-item ic-item"} key={ix}>
                    <div className="row h-100">
                        {itemComponents}
                    </div>
                </div>
            );
        });
        setCarouselItems(carouselItemsInitial);
        setCarouselIndicators(carouselIndicatorsInitial);
    }

    const jumpToIndex = (ix) => {
        setActiveIndex(ix);
        updateCarouselItems(ix);
    }

    const decreaseActiveIndex = () => {
        let newActiveIndex = activeIndex - 1 < 0 ? carouselItems.length - 1 : activeIndex - 1;
        setActiveIndex(newActiveIndex);
        updateCarouselItems(newActiveIndex);
    }

    const increaseActiveIndex = () => {
        let newActiveIndex = activeIndex + 1 == carouselItems.length ? 0 : activeIndex + 1;
        setActiveIndex(newActiveIndex);
        updateCarouselItems(newActiveIndex);
    }

    const setSelected = (resource) => {
        setSearchResults([]);
        setSearchTerm("");
        setModalShow(false);
        DataService.addEdge(props.goal.id, resource.id, position)
            .then((res) => {
                // TODO: This should get implemented without a request
                props.updatePath(props.goal.id);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    let heading = <h2 className='text-center my-5'>{t('externalResources')}</h2>;
    if (activeIndex > props.completedLength + props.externalLength - 1 && (props.goal.completed || props.resources[props.resources.length - 1] === null)) {
        heading = <h2 className='text-center my-5'>{t('congratulations')}</h2>
    } else if (activeIndex > props.completedLength + props.externalLength - 1) {
        heading = <h2 className='text-center my-5'>{t('recommendedResources')} {activeIndex + 1}</h2>
    } else if (activeIndex >= props.externalLength) {
        heading = <h2 className='text-center my-5'>{t('completedResourcesFrom')} {activeIndex + 1 - props.externalLength}</h2>;
    }

    return (
        <>
            {heading}
            <div className="row">
                <div className="col-1 text-end">
                    {carouselItems.length > 1
                        ? <button
                            className="btn btn-light"
                            onClick={decreaseActiveIndex}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                        // TODO: This invisible button keeps the distance between dots and heading
                        : <button className="btn btn-light invisible" onClick={decreaseActiveIndex}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                    }
                </div>
                <div className="col-10"></div>
                <div className="col-1 text-start">
                    {carouselItems.length > 1
                        ? <button
                            className="btn btn-light"
                            onClick={increaseActiveIndex}>
                            <i className="fa fa-arrow-right"></i>
                        </button>
                        // TODO: This invisible button keeps the distance between dots and heading
                        : <button className="btn btn-light invisible" onClick={decreaseActiveIndex}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div id={props.id} className="carousel" data-bs-interval="false">
                        <ol className="carousel-indicators ic-indicators">
                            {carouselIndicators}
                        </ol>
                        <div className="carousel-inner ic-inner">
                            {carouselItems}
                        </div>
                    </div>
                </div>
            </div>
            {activeIndex <= props.completedLength + props.externalLength && activeIndex >= props.externalLength
                ? <div className="text-center mt-3">
                    <button
                        className="btn primary"
                        onClick={() => { setModalShow(true); setPosition(activeIndex - props.externalLength); }}>
                        {t('insertResource')}
                    </button>
                </div>
                : <></>
            }
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('searchResource')}</h4>
                        <ResourceSearchSelect
                            setSelected={setSelected}
                            selectedId={null}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </>
                }
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}


export const ComponentTable = (props) => {
    let itemTable = null;
    if (props.components.length == 0) {
        itemTable = <></>
    } else {
        const columnComponents = [];
        props.components.forEach((item, index) => {
            columnComponents.push(<div key={index} className="col-md-6 mb-4">{item}</div>)
        });
        itemTable = <div className="row">{columnComponents}</div>;
    }

    return (itemTable);
};


export const Select = (props) => {
    const buttonSelect = () => {
        props.select(props.item);
    }

    const buttonDeselect = () => {
        props.deselect(props.item);
    }

    return (
        <div className='text-center'>
            {props.isSelected
                ? <button className="btn primary w-100" onClick={buttonDeselect}>{props.deselectText}</button>
                : <button className="btn btn-light w-100" onClick={buttonSelect}>{props.selectText}</button>
            }
        </div>
    );
};


export const Card = (props) => {
    return (
        <div className="card shadow-sm h-100">
            {
                props.header
                    ? <div className="card-header">{props.header}</div>
                    : <></>
            }
            <div className="card-body d-flex flex-column">
                {props.resource}
            </div>
            {
                props.footer
                    ? <div className="card-footer">{props.footer}</div>
                    : <></>
            }
        </div>
    );
};


export const truncate = (str, length) => {
    if (str != undefined) {
        return str.length > length ? str.substring(0, length - 3) + "..." : str;
    } else {
        return str;
    }
}


export const Rating = (props) => {
    const [rating, setRating] = useState(props.rating);
    const [rated, setRated] = useState(false);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const changeSlider = e => {
        setRated(false);
        setRating(e.target.value);
    };

    const sendRating = e => {
        props.endpoint(props.payloadBuilder(rating))
            .then((res) => {
                dispatch(createMessage({ feedbackSuccess: t('feedbackSubmitted') }));
                setRated(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const positivStyle = () => {
        if (rating > 0.5) {
            return { color: "#41527f" };
        } else {
            return {};
        }
    }

    const negativeStyle = () => {
        if (rating < 0.5) {
            return { color: "#41527f" };
        } else {
            return {};
        }
    }

    return (
        <div className="row">
            <div className="col-1 text-end" style={{ padding: "0px" }}>
                <i className="fas fa-thumbs-down" style={negativeStyle()}></i>
            </div>
            <div className="col-10 align-self-center">
                <input
                    type="range"
                    className="form-control-range w-100"
                    min="0"
                    max="1"
                    value={rating == undefined ? 0.5 : rating}
                    onChange={changeSlider}
                    onMouseUp={sendRating}
                    step="any"
                    disabled={rated ? true : false}
                />
            </div>
            <div className="col-1" style={{ padding: "0px" }}>
                <i className="fas fa-thumbs-up" style={positivStyle()}></i>
            </div>
        </div>
    );
};


export const ButtonRating = (props) => {
    const [rating, setRating] = useState(props.rating);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const sendRating = (rating) => {
        props.endpoint(props.payloadBuilder(rating))
            .then((res) => {
                dispatch(createMessage({ feedbackSuccess: t('feedbackSubmitted') }));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    return (
        <div className="row">
            <div className="col-1 align-self-center text-center">
                <i className="fas fa-thumbs-down" style={{color: "#41527f"}}></i>
            </div>
            <div className="col-2 align-self-center">
                <button type="button" className={rating === 0.2 ? "btn primary" : "btn primary-light"} onClick={() => { setRating(0.2); sendRating(0.2) }}>1</button>
            </div>
            <div className="col-2 align-self-center">
                <button type="button" className={rating === 0.4 ? "btn primary" : "btn primary-light"} onClick={() => { setRating(0.4); sendRating(0.4) }}>2</button>
            </div>
            <div className="col-2 align-self-center">
                <button type="button" className={rating === 0.5 ? "btn primary" : "btn primary-light"} onClick={() => { setRating(0.5); sendRating(0.5) }}>3</button>
            </div>
            <div className="col-2 align-self-center">
                <button type="button" className={rating === 0.6 ? "btn primary" : "btn primary-light"} onClick={() => { setRating(0.6); sendRating(0.6) }}>4</button>
            </div>
            <div className="col-2 align-self-center">
                <button type="button" className={rating === 0.8 ? "btn primary" : "btn primary-light"} onClick={() => { setRating(0.8); sendRating(0.8) }}>5</button>
            </div>
            <div className="col-1 align-self-center text-center">
                <i className="fas fa-thumbs-up" style={{color: "#41527f"}}></i>
            </div>
        </div>
    );
};


export const BinaryRating = (props) => {
    const [rating, setRating] = useState(props.rating);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const sendRating = (newRating) => {
        props.endpoint(props.payloadBuilder(newRating))
            .then((res) => {
                dispatch(createMessage({ feedbackSuccess: t('feedbackSubmitted') }));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    return (
        <div className="row">
            <div className="col-6">
                <button
                    className={rating < 0.5 ? "btn primary w-100" : props.light ? "btn primary-light-icons w-100" : "btn btn-light w-100"}
                    onClick={rating < 0.5 ? () => { } : () => { setRating(0.); sendRating(0.) }}>
                    <i className="fas fa-thumbs-down"></i>
                </button>
            </div>
            <div className="col-6">
                <button
                    className={rating > 0.5 ? "btn primary w-100" : props.light ? "btn primary-light-icons w-100" : "btn btn-light w-100"}
                    onClick={rating > 0.5 ? () => { } : () => { setRating(1.); sendRating(1.) }}>
                    <i className="fas fa-thumbs-up"></i>
                </button>
            </div>
        </div>
    );
};


export const CategoryIcon = (props) => {
    return (
        <>
            {props.category == 'book' ? <><i className="fa-solid fa-book" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'article' ? <><i className="fa-solid fa-newspaper" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'video' ? <><i className="fa-brands fa-youtube" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'course' ? <><i className="fa-solid fa-graduation-cap" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'chapter' ? <><i className="fa-solid fa-book-open" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'audio' ? <><i className="fa-solid fa-headphones" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'code' ? <><i className="fa-solid fa-code" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'other' ? <><i className="fa-solid fa-ghost" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'assignment' ? <><i className="fa-solid fa-square-poll-horizontal" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'animation' ? <><i className="fa-solid fa-file-video" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'simulation' ? <><i className="fa-solid fa-laptop-code" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'tutorial' ? <><i className="fa-solid fa-wave-square" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'workshop' ? <><i className="fa-solid fa-hammer" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'presentation' ? <><i className="fa-solid fa-chalkboard-user" style={{ color: "#41527f" }}></i> </> : <></>}
            {props.category == 'specification' ? <><i className="fa-solid fa-user-graduate" style={{ color: "#41527f" }}></i> </> : <></>}
        </>
    );
};


export const ModalBody = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {props.resource}
            </Modal.Body>
        </Modal>
    );
}


export const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};


export const Popup = (props) => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{props.header}</Popover.Header>
            <Popover.Body>
                {props.body}
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Overlay
                target={props.target.current}
                show={props.show}
                placement={props.position}
            >
                {popover}
            </Overlay>
        </>
    );
};


export const Accordion = (props) => {
    const { t, i18n } = useTranslation();

    let children = props.children;
    if (props.allowDrag) {
        children = props.children.map((child, ix) => (
            <Draggable
                draggableId={"draggable-" + ix}
                key={"draggable-" + ix}
                index={ix}
            >
                {(provided) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        {child}
                    </div>
                )}
            </Draggable>
        ));
    }

    if (props.children != undefined && props.children.length > 0) {
        return (
            <div className="accordion" id={"a" + props.id}>
                <div className="card">
                    <div className="card-header" id={"h" + props.id}>
                        <h2 className="mb-0">
                            <div className="row">
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-lg mb-0"
                                        placeholder={t('chapterHeading')}
                                        name="heading"
                                        value={props.value}
                                        onChange={(e) => { props.setValue(e.target.value) }}
                                    />
                                </div>
                                <div className="col-3 align-self-center">
                                    <div className="btn-group-vertical">
                                        <button className="btn btn-sm text-center"
                                            style={{ paddingBottom: "1px" }}
                                            onClick={() => { props.moveValueStepwise(true) }}
                                        >
                                            <div id="upvote"></div>
                                        </button>
                                        <button className="btn btn-sm text-center"
                                            style={{ paddingTop: "1px" }}
                                            onClick={() => { props.moveValueStepwise(false) }}
                                        >
                                            <div id="downvote"></div>
                                        </button>
                                    </div>
                                    <button className="btn btn-sm text-center mb-0" onClick={props.addValue}>
                                        <i className="fa-solid fa-square-plus"></i>
                                    </button>
                                    <button className="btn btn-sm text-center mb-0" onClick={props.deleteValue}>
                                        <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                    <button className="btn btn-sm text-center" type="button" data-bs-toggle="collapse" data-bs-target={"#c" + props.id} aria-expanded="true" aria-controls={"c" + props.id}>
                                        <i className="fa-solid fa-align-justify"></i>
                                    </button>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div id={"c" + props.id} className="collapse" aria-labelledby={"h" + props.id} data-bs-parent={"#a" + props.id}>
                        <div className="card-body">
                            {props.allowDrag
                                ?
                                <DragDropContext onDragEnd={props.moveValue}>
                                    <Droppable
                                        droppableId={"d" + props.id}
                                        direction="vertical"
                                        type="row"
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {children}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                : children
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="card">
                <div className="card-header" id={"h" + props.id}>
                    <h2 className="mb-0">
                        <div className="row">
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control form-control-lg mb-0"
                                    placeholder={t('chapterHeading')}
                                    name="heading"
                                    value={props.value}
                                    onChange={(e) => { props.setValue(e.target.value) }}
                                />
                            </div>
                            <div className="col-3 align-self-center">
                                <div className="btn-group-vertical">
                                    <button className="btn btn-sm text-center"
                                        style={{ paddingBottom: "1px" }}
                                        onClick={() => { props.moveValueStepwise(true) }}
                                    >
                                        <div id="upvote"></div>
                                    </button>
                                    <button className="btn btn-sm text-center"
                                        style={{ paddingTop: "1px" }}
                                        onClick={() => { props.moveValueStepwise(false) }}
                                    >
                                        <div id="downvote"></div>
                                    </button>
                                </div>
                                <button className="btn btn-sm text-center mb-0" onClick={props.addValue}>
                                    <i className="fa-solid fa-square-plus"></i>
                                </button>
                                <button className="btn btn-sm text-center mb-0" onClick={props.deleteValue}>
                                    <i className="fa-solid fa-trash-can"></i>
                                </button>
                            </div>
                        </div>
                    </h2>
                </div>
            </div>
        );
    }
};


export const makeid = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


// function that merges two arrays in alternating order
export const mergeArrays = (arr1, arr2) => {
    if (arr1 === null) {
        return arr2;
    } else if (arr2 === null) {
        return arr1;
    } else {
        let result = [];
        let i = 0;
        let j = 0;
        while (i < arr1.length && j < arr2.length) {
            result.push(arr1[i]);
            result.push(arr2[j]);
            i++;
            j++;
        }
        while (i < arr1.length) {
            result.push(arr1[i]);
            i++;
        }
        while (j < arr2.length) {
            result.push(arr2[j]);
            j++;
        }
        return result;
    }
}
