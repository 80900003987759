import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/auth';
import { createMessage } from '../../actions/messages';
import { withTranslation } from 'react-i18next';

export class Register extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        password2: '',
    };

    static propTypes = {
        register: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { username, email, password, password2 } = this.state;
        if (password !== password2) {
            this.props.createMessage({ passwordNotMatch: this.props.t("passwordMismatch") });
        } else {
            const newUser = {
                username,
                password,
                email,
            };
            this.props.register(newUser);
        }
    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { t } = this.props;
        if (this.props.isAuthenticated) {
            return <Navigate to="/" />;
        }
        const { username, email, password, password2 } = this.state;
        return (
            <div className="col-md-6 m-auto">
                <div className="card card-body mt-5">
                    <h2 className="text-center">{t("register")}</h2>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group mt-2">
                            <label>{t("username")}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                onChange={this.onChange}
                                value={username}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>{t("email")}</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={this.onChange}
                                value={email}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>{t("password")}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                onChange={this.onChange}
                                value={password}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>{t("passwordConfirmation")}</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password2"
                                onChange={this.onChange}
                                value={password2}
                            />
                        </div>

                        <p className='mt-3'>
                            {t("privacyStatement")} <a href='https://www.kraphx.com/privacy.html'>{t('privacy')}</a>
                        </p>

                        <div className="form-group mt-3 text-center">
                            <button type="submit" className="btn btn-lg primary">
                                {t("register")}
                            </button>
                        </div>
                        <div className='text-center'>
                            <p className='mt-3'>
                                {t("accountExists")} <Link to="/login">{t("login")}</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(withTranslation()(Register));
