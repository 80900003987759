import React, { useState, useEffect, useRef } from "react";
import { Search, SearchResultsSelect } from "./Search";
import { useDispatch } from "react-redux";
import '../../css/Home.css';
import { returnErrors, createMessage } from "../../actions/messages";
import DataService from "../../services/DataService";
import { useNavigate, useParams } from 'react-router-dom';
import { HomeTutorial, SearchResultTutorial } from "./Tutorial";
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Card, ComponentTable, mergeArrays, ModalBody } from "./Utils";
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import background from "../../img/background.png";
import { ResourceCompact } from "./Resources";
import { ResourceCompactSelect } from "./ResourceUtils";
import { YoutubeEmbed } from '../interface/Utils';


export const Home = () => {
    const [searchResults, setSearchResults] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [resourceGoalDict, setResourceGoalDict] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { query } = useParams();
    const [showHomeModal, setShowHomeModal] = useState(false);
    const [gitHub, setGitHub] = useState(false);
    const [semanticScholar, setSemanticScholar] = useState(false);
    const [showSearchResultModal, setShowSearchResultModal] = useState(false);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [rankingPrioritization, setRankingPrioritization] = useState("");
    const [featured, setFeatured] = useState([]);
    const [showFeaturedModal, setShowFeaturedModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    const updateResourceGoalDict = (resourceGoalDict, data) => {
        let newResourceGoalDict = { ...resourceGoalDict };
        data.forEach(resource => {
            if (resource.goal) {
                newResourceGoalDict[resource.id] = resource.goal;
            }
        });
        return newResourceGoalDict;
    }

    const searchResources = (query, app) => {
        DataService.search(query, true, app)
            .then((res) => {
                const results = res.data.results;
                // if external search ends before internal search, api returns [null]. In this case,
                // searchResults should not get set as the internal search results are still missing.
                if (results.length > 0 && results[0] !== null) {
                    setSearchResults(searchResults => { return mergeArrays(searchResults, results) });
                    setResourceGoalDict(resourceGoalDict => { return updateResourceGoalDict(resourceGoalDict, results) });
                } else if (results.length === 0) {
                    setSearchResults(searchResults => { return mergeArrays(searchResults, results) });
                }
                setLoading(false);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getFeatured = () => {
        DataService.getFeatured()
            .then((res) => {
                setResourceGoalDict(resourceGoalDict => { return updateResourceGoalDict(resourceGoalDict, res.data) });
                setFeatured(res.data);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    useEffect(() => {
        setGitHub(false);
        setSemanticScholar(false);
        setRankingPrioritization("");

        document.body.style.background = "";
        document.body.style.backgroundRepeat = "";
        document.body.style.backgroundSize = "";
        document.body.style.backgroundPosition = "";

        if (query) {
            const decodedQuery = decodeURIComponent(query).trim();
            // this triggers the loading spinner
            setSearchResults(null);
            // internal search
            searchResources(decodedQuery, 'kraphx');
            setSearchTerm(decodedQuery);
        } else {
            setSearchResults(null);
            setSearchTerm("");
        }

        DataService.getProfile()
            .then((res) => {
                i18n.changeLanguage(res.data.language);
                if (query == undefined && res.data.home) {
                    setShowHomeModal(true);
                } else if (query != undefined && res.data.search) {
                    setShowSearchResultModal(true);
                }
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }, [query]);

    useEffect(() => {
        if (!query) {
            getFeatured();
            document.body.style.background = `url('${background}')`;
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
            document.body.style.backgroundPosition = "center";
        }
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.background = "";
            document.body.style.backgroundRepeat = "";
            document.body.style.backgroundSize = "";
            document.body.style.backgroundPosition = "";
        };
    }, []);

    const select = (selectedContent) => {
        const payload = JSON.stringify({ pk: selectedContent.id });

        DataService.addGoal(payload)
            .then((res) => {
                dispatch(createMessage({ msg: t('Goal Added') }));
                let newResourceGoalDict = { ...resourceGoalDict };
                newResourceGoalDict[selectedContent.id] = res.data.id;
                setResourceGoalDict(newResourceGoalDict);
                if (searchResults) {
                    const newSearchResults = searchResults.slice();
                    newSearchResults.forEach((content) => {
                        if (content.id === selectedContent.id) {
                            content.goal = res.data.id;
                        }
                    });
                    setSearchResults(newSearchResults);
                }
                const newFeatured = featured.slice();
                featured.forEach((content) => {
                    if (content.id === selectedContent.id) {
                        content.goal = res.data.id;
                    }
                });
                setFeatured(newFeatured);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const goToGoal = (content) => {
        if (content.id in resourceGoalDict) {
            navigate("/goal/" + resourceGoalDict[content.id]);
        }
    }

    const isSelected = (content) => {
        return content.goal;
    }

    const featuredComponents = [];
    featured.forEach((content) => {
        const isSelected = content.goal ? true : false;
        featuredComponents.push(
            <ResourceCompactSelect
                resource={content}
                select={select}
                selectText={t('addAsGoal')}
                deselectText={t('goToGoal')}
                deselect={goToGoal}
                isSelected={isSelected}
            />
        );
    });

    return (
        <>
            {query != undefined
                ?
                <>
                    <div className='mx-auto w-75 mb-3'>
                        <Search
                            redirecting={true}
                            setSearchResults={setSearchResults}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            searchResults={searchResults}
                        />
                    </div>

                    <div className="row mb-3">
                        <div className="col-2 offset-md-3">
                            <button className="btn primary w-100">KraphX</button>
                        </div>
                        <div className="col-2">
                            <button
                                className={semanticScholar ? "btn primary w-100" : "btn btn-light w-100"}
                                onClick={() => {
                                    if (!semanticScholar) {
                                        setLoading(true);
                                        searchResources(searchTerm, 'semantic_scholar');
                                        setRankingPrioritization('www.semanticscholar.org');
                                        setSemanticScholar(true);
                                    }
                                }}>
                                Semantic Scholar
                            </button>
                        </div>
                        <div className="col-2">
                            <button
                                className={gitHub ? "btn primary w-100" : "btn btn-light w-100"}
                                onClick={() => {
                                    if (!gitHub) {
                                        setLoading(true);
                                        searchResources(searchTerm, 'github');
                                        setRankingPrioritization('github.com');
                                        setGitHub(true);
                                    }
                                }}>
                                GitHub
                            </button>
                        </div>
                    </div>

                    <SearchResultsSelect
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        select={select}
                        deselect={goToGoal}
                        selectText={t('addAsGoal')}
                        deselectText={t('goToGoal')}
                        isSelected={isSelected}
                        loading={loading}
                        prioritize={rankingPrioritization}
                    />
                </>
                :
                <>
                    <div className="row h-100">
                        <div className="col my-auto">
                            <h1 className="text-center mb-5 logo">{t('kraphx')}</h1>
                            <div className='mx-auto w-75 mb-5'>
                                <Search
                                    redirecting={true}
                                    setSearchResults={setSearchResults}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    searchResults={searchResults}
                                />
                            </div>
                            <div className="row mt-5"></div>
                            <div className="row mt-5">
                                <div className="text-center">
                                    {featured.length > 0
                                        ? <button
                                            className="btn primary btn-lg me-2"
                                            onClick={() => { setShowFeaturedModal(true) }}
                                        >
                                            {t('featured')}
                                        </button>
                                        : <></>
                                    }
                                    <button
                                        className="btn primary btn-lg ms-2 me-2"
                                        onClick={() => { setShowTutorialModal(true) }}
                                    >
                                        {t('tutorials')}
                                    </button>
                                    <a href='https://discord.gg/tgRrmkDvgn' target={'_blank'} className='btn btn-lg primary ms-2'>
                                        {t('joinDiscord')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <Offcanvas show={showHomeModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                <Offcanvas.Body>
                    <HomeTutorial setShow={setShowHomeModal} />
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas show={showSearchResultModal} onHide={() => { }} scroll={true} backdrop={true} placement={'end'}>
                <Offcanvas.Body>
                    <SearchResultTutorial setShow={setShowSearchResultModal} />
                </Offcanvas.Body>
            </Offcanvas>
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('featured')}</h4>
                        <ComponentTable components={featuredComponents} />
                    </>
                }
                show={showFeaturedModal}
                onHide={() => { setShowFeaturedModal(false); }}
            />
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('tutorials')}</h4>
                        <div>
                            {t('studentTutorial')}
                        </div>
                        <div className='text-center mt-3 mb-3'>
                            <YoutubeEmbed embedId="dDxYnZ2QYOU" />
                        </div>
                        <div>
                            {t('teacherTutorial')}
                        </div>
                        <div className='text-center mt-3'>
                            <YoutubeEmbed embedId="zdOAalHJ9lU" />
                        </div>
                    </>
                }
                show={showTutorialModal}
                onHide={() => { setShowTutorialModal(false); }}
            />
        </>
    );
};
