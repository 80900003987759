import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import { Home } from "./components/interface/Home";
import { Path } from './components/interface/Paths'
import { Profile } from './components/interface/Profile'
import { AddRelation, AddResource, AddTopic } from './components/interface/Add'
import Header from './components/layout/Header';
import Alerts from './components/layout/Alerts';
import Login from './components/accounts/Login';
import Register from './components/accounts/Register';
import PrivateRoute from './components/common/PrivateRoute';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { ScrollToTop, makeid } from './components/interface/Utils';
import { v4 as uuidv4 } from 'uuid';

// Alert Options
const alertOptions = {
    timeout: 3000,
    position: 'top center'
}

export default class App extends Component {
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Provider store={store}>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <Router>
                        <Fragment>
                            <Header />
                            <Alerts />
                            <div className="container h-100">
                                <ScrollToTop>
                                    <Routes>
                                        <Route exact path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                                        <Route exact path="/search/:query" element={<PrivateRoute><Home /></PrivateRoute>} />
                                        <Route exact path="/goal/:id" element={<PrivateRoute><Path /></PrivateRoute>} />
                                        <Route exact path="/resource/:id" element={<PrivateRoute><AddResource filled={true} /></PrivateRoute>} />
                                        <Route exact path="/topic/:id" element={<PrivateRoute><AddTopic filled={true} /></PrivateRoute>} />
                                        <Route exact path="/addRelation" element={<PrivateRoute><AddRelation /></PrivateRoute>} />
                                        <Route exact path="/addRelation/:id" element={<PrivateRoute><AddRelation relationType={'r'}/></PrivateRoute>}/>
                                        <Route exact path="/addAlternative/:id" element={<PrivateRoute><AddRelation relationType={'a'} /></PrivateRoute>}/>
                                        <Route exact path="/addConsolidation/:id" element={<PrivateRoute><AddRelation relationType={'c'} /></PrivateRoute>}/>
                                        <Route exact path="/addResource" element={<PrivateRoute><AddResource filled={false} /></PrivateRoute>} />
                                        <Route exact path="/addTopic" element={<PrivateRoute><AddTopic filled={false} /></PrivateRoute>} />
                                        <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                                        <Route exact path="/register" element={<Register />} />
                                        <Route exact path="/login" element={<Login />} />
                                    </Routes>
                                </ScrollToTop>
                            </div>
                        </Fragment>
                    </Router>
                </AlertProvider>
            </Provider>
        )
    }
}
