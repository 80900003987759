import React, { useState } from "react";
import { truncate, CategoryIcon, ModalBody, ComponentTable } from './Utils'
import { useDispatch } from "react-redux";
import { returnErrors } from "../../actions/messages";
import DataService from "../../services/DataService";
import { Card } from "./Utils";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useTranslation } from 'react-i18next';
import { EdgeList } from "./Profile";
import { ContentCompactEditDelete } from "./ResourceUtils";
import '../../languages/i18n';

export const ContentTitle = (props) => {
    const title = props.title;
    const maxLength = props.maxLength ? props.maxLength : 70;

    const tooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {title}
        </Tooltip>
    );

    return (
        <>
            {title.length > maxLength
                ? <OverlayTrigger
                    placement="top"
                    delay={{ show: 0, hide: 0 }}
                    overlay={tooltip}
                >
                    <h5><a href={props.link} target="_blank">{truncate(props.title, maxLength)}</a></h5>
                </OverlayTrigger>
                : <h5><a href={props.link} target="_blank">{truncate(props.title, maxLength)}</a></h5>
            }
        </>
    )
}


export const ContentDescription = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <p>{props.description ? truncate(props.description, 150) : t('noDescription')}</p>
    )
}


export const ContentIcons = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [parentModalShow, setParentModalShow] = useState(false);
    const [parentResource, setParentResource] = useState(null);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const getParent = (parentId) => {
        DataService.getResource(parentId)
            .then((res) => {
                setParentResource(res.data);
                setParentModalShow(true);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    return (
        <>
            <div className='my-3'>
                <h4>
                    {<><i className="fa-solid fa-circle-info" onClick={() => setModalShow(true)} style={{ cursor: "pointer", color: "#41527f" }}></i><span style={{ cursor: "pointer" }} onClick={() => setModalShow(true)}></span>&ensp;</>}
                    {props.resource.link != undefined ? <><span className="badge bg-light text-blue">{t('resource')}</span>&ensp;</> : <><span className="badge bg-light text-blue">{t('topic')}</span>&ensp;</>}
                    {props.resource.from_user ? <><span className="badge bg-light text-blue">{t('createdByUser')}</span>&ensp;</> : <></>}
                    {props.done != undefined && props.todo != undefined ? <><span className="badge bg-light text-blue"><i className="fa-solid fa-check"></i> {props.done} of {props.todo + props.done}&ensp;</span>&ensp;</> : <></>}
                    {props.resource.parent != undefined ? <><i className="fa-solid fa-arrow-up-right-from-square text-blue" style={{ cursor: "pointer" }} onClick={() => getParent(props.resource.parent)}></i>&ensp;</> : <></>}
                    <CategoryIcon category={props.resource.category} />
                    {props.resource.approved ? <>&ensp;<i className="fa-solid fa-check text-blue"></i></> : <></>}
                </h4>
            </div>

            <ModalBody
                resource={<ResourceDetailed resource={props.resource} setCurrentModal={setModalShow} getParent={getParent} />}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <ModalBody
                resource={<ResourceDetailed resource={parentResource} setCurrentModal={setParentModalShow} getParent={getParent} />}
                show={parentModalShow}
                onHide={() => setParentModalShow(false)}
            />
        </>
    )
}


export const ResourceMinimal = (props) => {
    return (
        <div>
            <ContentTitle link={props.resource.link} title={props.resource.title} maxLength={40} />
            <ContentIcons {...props} />
        </div>
    );
};


export const ResourceCompact = (props) => {
    return (
        <>
            <ContentTitle title={props.resource.title} link={props.resource.link} />
            <ContentIcons {...props} />
            <ContentDescription description={props.resource.description} />
        </>
    );
};


export const ResourceDetailed = (props) => {
    const [contents, setContents] = useState([]);
    const [requirementProposals, setRequirementProposals] = useState([]);
    const [consolidationProposals, setConsolidationProposals] = useState([]);
    const [topicProposals, setTopicProposals] = useState([]);
    const [alternativeProposals, setAlternativeProposals] = useState([]);
    const [showNeighborhood, setShowNeighborhood] = useState(false);
    const dispatch = useDispatch();
    let fees = null;
    const { t, i18n } = useTranslation();

    const getResourceNeighborhood = (id) => {
        DataService.getResourceNeighborhood(id)
            .then((res) => {
                setAlternativeProposals(res.data.similarity_proposals)
                setRequirementProposals(res.data.dependency_proposals)
                setConsolidationProposals(res.data.consolidation_proposals)
                setTopicProposals(res.data.topics)
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const topicComponents = [];
    topicProposals.forEach((topic) => {
        topicComponents.push(
            <ContentCompactEditDelete
                resource={topic}
                removeResource={(item) => { removeFromNeighborhood(DataService.deleteTopicProposal, item) }}
                link={'topic'}
                key={topic.id}
            />
        );
    });

    const removeFromNeighborhood = (endpoint, item) => {
        endpoint(item.id)
            .then((res) => {
                // TODO: this could get done without a request
                getResourceNeighborhood(props.resource.id);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    let noNeighborhood = <></>;
    if (topicComponents.length == 0 &&
        consolidationProposals.length == 0 &&
        requirementProposals.length == 0 &&
        alternativeProposals.length == 0) {
        noNeighborhood = <div className="text-center"><p>{t('noNeighborhood')}</p></div>
    }

    let neighborhoodSection = <></>
    if (showNeighborhood) {
        neighborhoodSection = <>
            <hr />
            <div className="mb-5 mt-5 align-self-center">
                <h4 className='text-center'>{t('neighborhood')}</h4>
            </div>
            {noNeighborhood}
            {topicComponents.length > 0
                ? <div className="mb-5">
                    <div className="mb-5 align-self-center">
                        <h4 className='text-center'>{t('topics')}</h4>
                    </div>
                    <ComponentTable components={topicComponents} />
                </div>
                : <></>
            }
            {consolidationProposals.length > 0
                ? <EdgeList
                    edges={consolidationProposals}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteEdgeProposal, item) }}
                    relationText={t('deepensUnderstandingOf')} />
                : <></>
            }
            {requirementProposals.length > 0
                ? <EdgeList
                    edges={requirementProposals}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteDependencyEdgeProposal, item) }}
                    relationText={t('requiredToUnderstand')} />
                : <></>
            }
            {alternativeProposals.length > 0
                ? <EdgeList
                    edges={alternativeProposals}
                    alternative={true}
                    removeEdge={(item) => { removeFromNeighborhood(DataService.deleteSimilarityProposal, item) }}
                    relationText={t('isAlternative')} />
                : <></>
            }
        </>
    }

    if (props.resource.fees != undefined) {
        props.resource.fees ? fees = t('no') : fees = t('yes');
    }

    const showContents = () => {
        DataService.getTopic(props.resource.id)
            .then((res) => {
                setContents(res.data.topics.concat(res.data.resources));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const detailedTopic = () => {
        if (contents.length == 0) {
            return <div className="text-center">
                <button className="btn primary" onClick={showContents}>{t('showContents')}</button>
            </div>
        } else {
            const contentComponents = [];
            contents.forEach((content) => {
                contentComponents.push(
                    <Card
                        resource={
                            <ResourceCompact resource={content} key={content.id} />
                        }
                    />
                )
            });
            return <ComponentTable components={contentComponents} />
        }
    }

    const getParent = (parentId) => {
        props.getParent(parentId);
        if (props.setCurrentModal) {
            props.setCurrentModal(false);
        }
    }

    return (
        <>
            <ContentTitle title={props.resource.title} link={props.resource.link} />
            <p>{props.resource.description ? props.resource.description : t('noDescription')}</p>
            {props.resource.parent != undefined ? <p><strong>{t('parent')}: </strong><><i className="fa-solid fa-arrow-up-right-from-square" onClick={() => getParent(props.resource.parent)}></i></></p> : <></>}
            {props.resource.author ? <p><strong>{t('author')}: </strong>{props.resource.author}</p> : <></>}
            {props.resource.category ? <p><strong>{t('category')}: </strong>{props.resource.category} <CategoryIcon category={props.resource.category} /></p> : <></>}
            {props.resource.language ? <p><strong>{t('language')}: </strong>{props.resource.language}</p> : <></>}
            {fees ? <p><strong>{t('fees')}: </strong>{fees}</p> : <></>}
            {props.resource.duration ? <p><strong>{t('duration')}: </strong>{props.resource.duration}</p> : <></>}
            {props.resource.approved ? <p><strong>{t('approved')}: </strong><>&ensp;<i className="fa-solid fa-check"></i></></p> : <></>}
            {props.resource.link
                ? <></>
                : detailedTopic()
            }
            {(props.resource.approved || props.resource.from_user === null) && props.resource.link ?
                <div className="text-center">
                    <button className="btn primary mw-2" onClick={() => { getResourceNeighborhood(props.resource.id); setShowNeighborhood(true) }}>{t('neighborhood')}</button>
                </div>
                : <></>}
            {neighborhoodSection}
        </>
    );
};
