export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SEARCH = 'SEARCH';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const GET_GOALS = 'GET_GOALS';
export const ADD_GOAL = 'ADD_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';
export const GET_GOAL = 'GET_GOAL';
export const GET_PATH = 'GET_PATH';
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const VISIT_RECOMMENDATION = 'VISIT_RECOMMENDATION';
export const CHANGE_UNDERSTANDING_SLIDER = 'CHANGE_UNDERSTANDING_SLIDER';
export const CHANGE_QUALITY_SLIDER = 'CHANGE_QUALITY_SLIDER';