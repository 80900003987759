import React from "react";
import { ResourceCompact } from "./Resources";
import { Select, ComponentTable, Card } from "./Utils";
import { Search } from "./Search";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import { t } from "i18next";
import { v4 as uuidv4 } from 'uuid';


export const ResourceCompactSelect = (props) => {
    const { t, i18n } = useTranslation();
    let selectText = props.selectText;
    let deselectText = props.deselectText;
    if (selectText == undefined) selectText = t('select');
    if (deselectText == undefined) deselectText = t('deselect');

    return (
        <Card
            resource={
                <ResourceCompact resource={props.resource} />
            }
            footer={
                <Select
                    select={props.select}
                    deselect={props.deselect}
                    item={props.resource}
                    isSelected={props.isSelected}
                    selectText={selectText}
                    deselectText={deselectText}
                />
            }
        />
    );
};


export const ResourceCompactButtons = (props) => {
    return (
        <Card
            resource={<ResourceCompact resource={props.resource} />}
            footer={props.buttons}
        />
    );
};


export const ContentCompactEditDelete = (props) => {
    const { t, i18n } = useTranslation();

    const remove = () => {
        props.removeResource(props.resource);
    }

    return (
        <Card
            resource={
                <ResourceCompact resource={props.resource} />
            }
            footer={
                <div className="row">
                    <div className="col-6">
                        <Link to={`/${props.link}/` + props.resource.id} className="btn btn-light w-100">{t('edit')}</Link>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-light w-100" onClick={remove}>{t('delete')}</button>
                    </div>
                </div>
            }
        />
    );
};


export const ResourceSearchSelect = (props) => {
    const { t, i18n } = useTranslation();

    const select = (resource) => {
        props.setSelected(resource);
    }

    const deselect = (resource) => {
        props.setSelected(null);
    }

    let resultView = <></>;
    if (props.searchResults === undefined) {
        resultView = <></>;
    } else if (props.searchResults === null) {
        resultView =
            <div className="text-center mt-3">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t('loading')}</span>
                </div>
            </div>
    } else {
        const searchResultComponents = [];
        props.searchResults.forEach((resource) => {
            searchResultComponents.push(
                <ResourceCompactSelect
                    resource={resource}
                    isSelected={resource.id === props.selectedId}
                    select={select}
                    deselect={deselect}
                    key={resource.id}
                />
            );
        });
        if (searchResultComponents.length === 0) {
            resultView =
                <>
                    <hr />
                    <div className="text-center mb-5">
                        <Link to={'/addResource'} state={{ name: uuidv4() }} className='btn btn-light'>{t('addMissing')}</Link>
                    </div>
                    <div className="text-center">
                        <h5>{t('noResults')}</h5>
                    </div>
                </>
        } else {
            resultView =
                <>
                    <hr />
                    <div className="text-center mb-5">
                        <Link to={'/addResource'} state={{ name: uuidv4() }} className='btn btn-light'>{t('addMissing')}</Link>
                    </div>
                    <ComponentTable components={searchResultComponents} />
                </>
        }
    }

    return (
        <>
            <Search
                setSearchResults={props.setSearchResults}
                searchTerm={props.searchTerm}
                setSearchTerm={props.setSearchTerm}
                includeTopics={false}
                searchResults={props.searchResults}
            />
            {resultView}
        </>
    );
};


export const ResourceSearchMultiSelect = (props) => {
    const { t, i18n } = useTranslation();

    const select = (resource) => {
        const newSelectedResources = props.selectedResources.slice();
        newSelectedResources.push(resource)
        props.setSelectedResources(newSelectedResources);
    }

    const deselect = (resource) => {
        const newSelectedResources = [];
        props.selectedResources.forEach((selectedResource) => {
            if (selectedResource.id !== resource.id) {
                newSelectedResources.push(selectedResource);
            }
        });
        props.setSelectedResources(newSelectedResources);
    }

    const selectedIds = [];
    props.selectedResources.forEach((selectedResource) => {
        selectedIds.push(selectedResource.id)
    });

    let resultView = <></>;
    if (props.searchResults === undefined) {
        resultView = <></>;
    } else if (props.searchResults === null) {
        resultView =
            <div className="text-center mt-3">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t('loading')}</span>
                </div>
            </div>
    } else {
        const searchResultComponents = [];
        props.searchResults.forEach((resource) => {
            searchResultComponents.push(
                <ResourceCompactSelect
                    resource={resource}
                    isSelected={selectedIds.includes(resource.id)}
                    select={select}
                    deselect={deselect}
                    key={resource.id}
                />
            );
        });
        if (props.searchResults.length > 0) {
            resultView =
                <>
                    <hr />
                    <div className="text-center mb-5">
                        <Link to={"/addResource"} className='btn btn-light'>{t('addMissing')}</Link>
                    </div>
                    <ComponentTable components={searchResultComponents} />
                </>
        } else {
            resultView =
                <>
                    <hr />
                    <div className="text-center mb-5">
                        <Link to={"/addResource"} className='btn btn-light'>{t('addMissing')}</Link>
                    </div>
                    <div className="text-center mb-3">
                        {t('noContents')}
                    </div>
                </>
        }
    }

    return (
        <>
            <Search
                includeTopics={props.includeTopics}
                setSearchResults={props.setSearchResults}
                searchTerm={props.searchTerm}
                setSearchTerm={props.setSearchTerm}
                searchResults={props.searchResults}
            />
            {resultView}
        </>
    );
};