import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { returnErrors, createMessage } from "../../actions/messages"
import { ResourceCompact } from "./Resources";
import { ContentCompactEditDelete } from "./ResourceUtils";
import { ComponentTable, Card, ModalBody } from "./Utils";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { LearningGoal } from './Goals'
import DataService from '../../services/DataService';
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';
import { Link } from 'react-router-dom';
import emptyImage from '../../img/empty.png'
import { ResourceCompactSelect } from './ResourceUtils';


export const Profile = () => {
    const dispatch = useDispatch();
    const [resourceProposals, setResourceProposals] = useState([]);
    const [dependencyProposals, setDependencyProposals] = useState([]);
    const [pathProposals, setPathProposals] = useState([]);
    const [topicProposals, setTopicProposals] = useState([]);
    const [alternativeProposals, setAlternativeProposals] = useState([]);
    const [goals, setGoals] = useState([]);
    const [knowledge, setKnowledge] = useState([]);
    const [key, setKey] = useState('goals');
    const [subKey, setSubKey] = useState('currentGoals');
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [startPage, setStartPage] = useState(0);
    const [showCurrent, setShowCurrent] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [resourceSearch, setResourceSearch] = useState(true);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [topicExtensionModal, setTopicExtensionModal] = useState(false);
    const [topicSearchTerm, setTopicSearchTerm] = useState('');
    const [topicSearchResults, setTopicSearchResults] = useState(null);
    const [topicSearchActive, setTopicSearchActive] = useState(false);
    const [chosenResource, setChosenResource] = useState(null);
    const { t, i18n } = useTranslation();

    const getCurrentGoals = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getGoals(newPage * limit, limit)
            .then((res) => {
                setGoals(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getCompletedGoals = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getCompletedGoals(newPage * limit, limit)
            .then((res) => {
                setGoals(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getResourceProposals = (p) => {
        // switch to resource search function in modal
        setResourceSearch(true);
        let newPage = p == undefined ? 0 : p;
        if (searchTerm && resourceSearch) {
            DataService.resourceSearch(searchTerm, newPage * limit, limit)
                .then((res) => {
                    setResourceProposals(res.data['results']);
                    setCount(res.data.count);
                    setPage(newPage);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        } else {
            DataService.getResourceProposals(newPage * limit, limit)
                .then((res) => {
                    setResourceProposals(res.data['results']);
                    setCount(res.data.count);
                    setPage(newPage);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    const getDependencyProposals = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getDependencyEdgeProposals(newPage * limit, limit)
            .then((res) => {
                setDependencyProposals(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getPathProposals = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getEdgeProposals(newPage * limit, limit)
            .then((res) => {
                setPathProposals(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getTopicProposals = (p) => {
        // switch to topic search function in modal
        setResourceSearch(false);
        let newPage = p == undefined ? 0 : p;
        if (searchTerm && !resourceSearch) {
            DataService.topicSearch(searchTerm, newPage * limit, limit)
                .then((res) => {
                    setTopicProposals(res.data['results']);
                    setCount(res.data.count);
                    setPage(newPage);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        } else {
            DataService.getTopicProposals(newPage * limit, limit)
                .then((res) => {
                    setTopicProposals(res.data['results']);
                    setCount(res.data.count);
                    setPage(newPage);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    const getSimilarityProposals = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getSimilarityProposals(newPage * limit, limit)
            .then((res) => {
                setAlternativeProposals(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    const getKnowledge = (p) => {
        const newPage = p == undefined ? 0 : p;
        DataService.getKnowledge(newPage * limit, limit)
            .then((res) => {
                setKnowledge(res.data['results']);
                setCount(res.data.count);
                setPage(newPage);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    };

    useEffect(() => {
        if (key == 'goals') setSubKey('currentGoals');
        if (key == 'contributions') setSubKey('resources');
        if (key == 'knowledge') getKnowledge();
    }, [key]);

    useEffect(() => {
        const getFunctions = {
            'currentGoals': getCurrentGoals,
            'completedGoals': getCompletedGoals,
            'resources': getResourceProposals,
            'topics': getTopicProposals,
            'consolidations': getPathProposals,
            'requirements': getDependencyProposals,
            'alternatives': getSimilarityProposals
        }
        const getFunction = getFunctions[subKey];
        setPage(0);
        setStartPage(0);
        if (subKey == 'resources' || subKey == 'topics') setSearchTerm("");
        if (getFunction != undefined) getFunction();
    }, [subKey]);

    const removeResource = (resource) => {
        DataService.deleteResourceProposal(resource.id)
            .then((res) => {
                // TODO: this could get done without a request
                getResourceProposals(page);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const removeTopic = (topic) => {
        DataService.deleteTopicProposal(topic.id)
            .then((res) => {
                // TODO: this could get done without a request
                getTopicProposals(page);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const removeDependency = (dependency) => {
        DataService.deleteDependencyEdgeProposal(dependency.id)
            .then((res) => {
                // TODO: this could get done without a request
                getDependencyProposals(page);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const removePath = (path) => {
        DataService.deleteEdgeProposal(path.id)
            .then((res) => {
                // TODO: this could get done without a request
                getPathProposals(page);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const removeAlternative = (alternative) => {
        DataService.deleteSimilarityProposal(alternative.id)
            .then((res) => {
                // TODO: this could get done without a request
                getSimilarityProposals(page);
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const deleteGoal = (goalToDelete) => {
        DataService.deleteGoal(goalToDelete.id)
            .then((res) => {
                const newGoals = [];
                goals.forEach((goal) => {
                    if (goal.id !== goalToDelete.id) newGoals.push(goal);
                });
                setGoals(newGoals);
                dispatch(createMessage({ addLead: t('goalDeleted') }));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
    }

    const generatePageButtons = (targetGetFunction) => {
        const buttons = [];
        if (startPage > 0 && count > 0) {
            buttons.push(
                <div
                    className={page < startPage ? 'btn primary ms-1 me-1' : 'btn btn-light ms-1 me-1'}
                    onClick={() => { setStartPage(Math.max(0, startPage - 10)) }}
                    key={'previous'}
                >
                    <i className="fas fa-angle-double-left"></i>
                </div>
            );
        }
        for (let i = startPage; i < Math.min(startPage + 10, Math.ceil(count / limit)); i++) {
            buttons.push(
                <div
                    className={page === i ? 'btn primary ms-1 me-1' : 'btn btn-light ms-1 me-1'}
                    onClick={() => { targetGetFunction(i) }}
                    key={i}
                >
                    {i + 1}
                </div>
            );
        }
        if (Math.ceil(count / limit) > startPage + 10) {
            buttons.push(
                <div
                    className={page > startPage + 10 ? 'btn primary ms-1 me-1' : 'btn btn-light ms-1 me-1'}
                    onClick={() => { setStartPage(Math.min(Math.ceil(count / limit) - 10, startPage + 10)) }}
                    key={'next'}
                >
                    <i className="fas fa-angle-double-right"></i>
                </div>
            );
        }
        // don't show single page button
        if (buttons.length > 1) {
            return buttons;
        } else {
            return [];
        }
    }

    const goalComponents = [];
    goals.forEach((goal) => {
        goalComponents.push(
            <LearningGoal
                goal={goal}
                deleteGoal={deleteGoal}
                key={goal.id}
            />
        );
    });

    const goalPageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(showCurrent ? getCurrentGoals : getCompletedGoals)}
    </div>

    const dependencyPageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getDependencyProposals)}
    </div>

    const pathPageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getPathProposals)}
    </div>

    const alternativePageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getSimilarityProposals)}
    </div>

    const goalSection =
        <div>
            <h4 className='text-center mb-5'>
                {subKey === 'currentGoals' ? t('yourCurrentGoals') : t('yourCompletedGoals')}
            </h4>
            {goalComponents.length > 0
                ?
                <>
                    <ComponentTable components={goalComponents} />
                    <div className='text-center mt-3'>{goalPageButtonGroup}</div>
                </>
                :
                <>
                    <p className="text-center mt-5">{t('noGoals')}</p>
                    <div className="text-center mt-3">
                        <img src={emptyImage} className="img-fluid" style={{ height: "400px", width: "400px" }}></img>
                    </div>
                    <div className="text-center mt-3">
                        <a href="https://storyset.com/work">Illustration by Storyset</a>
                    </div>
                </>
            }
        </div>

    const resourceComponents = [];
    resourceProposals.forEach((resource) => {
        resourceComponents.push(
            <Card
                resource={
                    <ResourceCompact resource={resource} />
                }
                footer={
                    <>
                        <div className="row">
                            <div className="col-6">
                                <Link to={`/resource/` + resource.id} className="btn btn-light w-100">{t('edit')}</Link>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-light w-100" onClick={() => { removeResource(resource) }}>{t('delete')}</button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <Link to={"/addAlternative/" + resource.id} className="btn w-100 btn-light">{t('addAlternative')}</Link>
                            </div>
                            <div className="col-6">
                                <Link to={"/addRelation/" + resource.id} className="btn w-100 btn-light">{t('addRequirement')}</Link>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <Link to={"/addConsolidation/" + resource.id} className="btn w-100 btn-light">{t('addConsolidation')}</Link>
                            </div>
                            <div className="col-6">
                                <button className="btn w-100 btn-light" onClick={() => { setTopicExtensionModal(true); setChosenResource(resource); }}>{t('topic')}</button>
                            </div>
                        </div>
                    </>
                }
                key={resource.id}
            />
        );
    });

    const resourcePageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getResourceProposals)}
    </div>

    let resourceSection = null
    if (resourceComponents.length > 0) {
        resourceSection = <div className='mb-5'>
            <ComponentTable components={resourceComponents} />
            <div className='text-center mt-3'>{resourcePageButtonGroup}</div>
        </div>
    } else {
        resourceSection =
            <>
                <p className='text-center mb-5'>{t('noProposedResources')}</p>
                <div className="text-center mt-3">
                    <img src={emptyImage} className="img-fluid" style={{ height: "400px", width: "400px" }}></img>
                </div>
                <div className="text-center mt-3">
                    <a href="https://storyset.com/work">Illustration by Storyset</a>
                </div>
            </>
    }

    const topicComponents = [];
    topicProposals.forEach((topic) => {
        topicComponents.push(
            <ContentCompactEditDelete
                resource={topic}
                removeResource={removeTopic}
                link={'topic'}
                key={topic.id}
            />
        );
    });

    const topicPageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getTopicProposals)}
    </div>

    let topicSection = null
    if (topicComponents.length > 0) {
        topicSection = <div className='mb-5'>
            <ComponentTable components={topicComponents} />
            <div className='text-center mt-3'>{topicPageButtonGroup}</div>
        </div>
    } else {
        topicSection =
            <>
                <p className='text-center mb-5'>{t('noProposedTopics')}</p>
                <div className="text-center mt-3">
                    <img src={emptyImage} className="img-fluid" style={{ height: "400px", width: "400px" }}></img>
                </div>
                <div className="text-center mt-3">
                    <a href="https://storyset.com/work">Illustration by Storyset</a>
                </div>
            </>
    }

    const knowledgeComponents = [];
    knowledge.forEach((resource) => {
        knowledgeComponents.push(
            <Card
                resource={
                    <ResourceCompact
                        resource={resource}
                        key={resource.id}
                    />
                }
            />
        );
    });

    const knowledgePageButtonGroup = <div className="btn-group" role="group">
        {generatePageButtons(getKnowledge)}
    </div>

    let knowledgeSection = null
    if (knowledgeComponents.length > 0) {
        knowledgeSection = <div className='mb-5'>
            <ComponentTable components={knowledgeComponents} />
            <div className='text-center mt-3'>{knowledgePageButtonGroup}</div>
        </div>
    } else {
        knowledgeSection =
            <>
                <p className='text-center mb-5'>{t('noKnowledge')}</p>
                <div className="text-center mt-3">
                    <img src={emptyImage} className="img-fluid" style={{ height: "400px", width: "400px" }}></img>
                </div>
                <div className="text-center mt-3">
                    <a href="https://storyset.com/work">Illustration by Storyset</a>
                </div>
            </>
    }

    const handleKeyPress = e => {
        if (e.charCode == 13) {
            setShowSearchModal(false);
            if (resourceSearch) {
                getResourceProposals();
            } else {
                getTopicProposals();
            }
        }
    };

    const addResourceToTopic = (topic) => {
        DataService.extendTopic(topic.id, chosenResource.id)
            .then(() => {
                dispatch(createMessage({ topicExtended: t('topicExtended') }));
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
            });
        setTopicSearchActive(false);
        setTopicSearchTerm('');
        setTopicExtensionModal(false);
        setTopicSearchResults(null);
    }

    const handleTopicSearch = e => {
        if (e.charCode == 13) {
            setTopicSearchActive(true);
            DataService.topicSearch(topicSearchTerm, 0, 20)
                .then((res) => {
                    const topicSearchComponents = [];
                    res.data['results'].forEach((topic) => {
                        topicSearchComponents.push(
                            <ResourceCompactSelect
                                resource={topic}
                                isSelected={false}
                                select={addResourceToTopic}
                                deselect={() => { }}
                                key={topic.id}
                            />
                        );
                    });
                    setTopicSearchResults(topicSearchComponents);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    let topicSearchSection = <div className="spinner-border" role="status">
        <span className="visually-hidden">{t('loading')}</span>
    </div>
    if (topicSearchResults !== null) {
        if (topicSearchResults.length > 0) {
            topicSearchSection = <ComponentTable components={topicSearchResults} />
        } else {
            topicSearchSection = <p className='text-center mb-5'>{t('noTopics')}</p>
        }
    }

    return (
        <>
            <div className='mb-5'>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3" variant='pills'>
                    <Tab eventKey="goals" title={t('goals')}>
                        <hr />
                        <Tabs activeKey={subKey} onSelect={(k) => setSubKey(k)} className="mb-3" variant='pills'>
                            <Tab eventKey="currentGoals" title={t('currentGoals')}>
                                {goalSection}
                            </Tab>
                            <Tab eventKey="completedGoals" title={t('completedGoals')}>
                                {goalSection}
                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="contributions" title={t('contributions')}>
                        <hr />
                        <Tabs activeKey={subKey} onSelect={(k) => setSubKey(k)} className="mb-3" variant='pills'>
                            <Tab eventKey="resources" title={t('resources')}>
                                <h4 className='text-center mb-3 mt-5'>{t('resourceContributions')}</h4>
                                <div className='text-center mb-5'>
                                    <button className={searchTerm ? 'btn primary' : 'btn btn-light'} onClick={() => { setShowSearchModal(true) }}><i className="fas fa-filter"></i></button>
                                </div>
                                {resourceSection}
                            </Tab>
                            <Tab eventKey="topics" title={t('topics')}>
                                <h4 className='text-center mb-3 mt-5'>{t('topicContributions')}</h4>
                                <div className='text-center mb-5'>
                                    <button className={searchTerm ? 'btn primary' : 'btn btn-light'} onClick={() => { setShowSearchModal(true) }}><i className="fas fa-filter"></i></button>
                                </div>
                                {topicSection}
                            </Tab>
                            <Tab eventKey="requirements" title={t('requirements')}>
                                <h4 className='text-center mb-5 mt-5'>{t('requirementContributions')}</h4>
                                <EdgeList
                                    edges={dependencyProposals}
                                    removeEdge={removeDependency}
                                    relationText={t('requiredToUnderstand')}
                                />
                                <div className='text-center mt-3'>{dependencyPageButtonGroup}</div>
                            </Tab>
                            <Tab eventKey="consolidations" title={t('consolidations')}>
                                <h4 className='text-center mb-5 mt-5'>{t('consolidationContributions')}</h4>
                                <EdgeList
                                    edges={pathProposals}
                                    removeEdge={removePath}
                                    relationText={t('deepensUnderstandingOf')}
                                />
                                <div className='text-center mt-3'>{pathPageButtonGroup}</div>
                            </Tab>
                            <Tab eventKey="alternatives" title={t('alternatives')}>
                                <h4 className='text-center mb-5 mt-5'>{t('alternativeContributions')}</h4>
                                <EdgeList
                                    alternative={true}
                                    edges={alternativeProposals}
                                    removeEdge={removeAlternative}
                                    relationText={t('isAlternative')}
                                />
                                <div className='text-center mt-3'>{alternativePageButtonGroup}</div>
                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="knowledge" title={t('knowledge')}>
                        <hr />
                        <h4 className='text-center mb-5 mt-5'>{t('currentKnowledge')}</h4>
                        {knowledgeSection}
                    </Tab>
                </Tabs>
            </div>
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('filterBy')}</h4>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder={t("enterQuery")}
                                name="searchTitle"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            {searchTerm
                                ? <div className='text-center'>
                                    <button
                                        className='btn btn-light mt-3'
                                        onClick={() => {
                                            setSearchTerm('');
                                        }}
                                    >
                                        {t('clearFilter')}
                                    </button>
                                </div>
                                : <></>
                            }
                        </div>
                    </>
                }
                show={showSearchModal}
                onHide={() => {
                    setShowSearchModal(false);
                    if (resourceSearch) {
                        getResourceProposals();
                    } else {
                        getTopicProposals();
                    }
                }}
            />
            <ModalBody
                resource={
                    <>
                        <h4 className="text-center mb-3 mt-2">{t('searchTopic')}</h4>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder={t("enterQuery")}
                                name="searchTitle"
                                value={topicSearchTerm}
                                onChange={(e) => setTopicSearchTerm(e.target.value)}
                                onKeyPress={handleTopicSearch}
                            />
                        </div>
                        {topicSearchActive
                            ? <>
                                <hr />
                                {topicSearchSection}
                            </>
                            : <></>
                        }
                    </>
                }
                show={topicExtensionModal}
                onHide={() => { setTopicExtensionModal(false); }}
            />
            <br />
        </>
    );
};


export const EdgeList = (props) => {
    const { t, i18n } = useTranslation();

    const edgeComponents = [];
    props.edges.forEach((edge) => {
        let component = null;
        if (props.alternative) {
            component = <Alternative
                edge={edge}
                removeEdge={props.removeEdge}
                key={edge.id}
            />
        } else {
            component = <Edge
                edge={edge}
                removeEdge={props.removeEdge}
                key={edge.id}
            />
        }
        edgeComponents.push(
            component
        );
    });

    let listContent = <div className="row mb-5">
        <div className="col-5 align-self-center">
            <h4 className='text-center'>{t('contentA')}</h4>
        </div>
        <div className="col-2 align-self-center">
            <h4 className='text-center'>{props.relationText}</h4>
        </div>
        <div className="col-5 align-self-center">
            <h4 className='text-center'>{t('contentB')}</h4>
        </div>
    </div>
    if (edgeComponents.length > 0) {
        listContent = <>
            {listContent}
            {edgeComponents}
        </>
    } else {
        listContent = <>
            {listContent}
            <p className='text-center'>{t('noContributions')}</p>
            <div className="text-center mt-3">
                <img src={emptyImage} className="img-fluid" style={{ height: "400px", width: "400px" }}></img>
            </div>
            <div className="text-center mt-3">
                <a href="https://storyset.com/work">Illustration by Storyset</a>
            </div>
        </>
    }

    return (
        listContent
    );
};


export const Edge = (props) => {
    const { t, i18n } = useTranslation();

    const remove = () => {
        props.removeEdge(props.edge);
    }

    return (
        <div className="row mb-5">
            <div className="col-5 align-self-center">
                <Card
                    resource={<ResourceCompact resource={props.edge.destination} />}
                    footer={props.edge.destination.from_user ? <Link to={`/resource/` + props.edge.destination.id} className="btn btn-light w-100">{t('edit')}</Link> : undefined}
                />
            </div>
            <div className="col-2 align-self-center">
                {props.edge.approved
                    ?
                    <div className='row'>
                        <div className='text-center mb-4'>
                            <h4><i className="fa-solid fa-check"></i></h4>
                        </div>
                    </div>
                    :
                    <></>}
                <div className='row'>
                    <div className='text-center mb-4'>
                        <button className="btn btn-light w-100" onClick={remove}>{t('delete')}</button>
                    </div>
                </div>
            </div>
            <div className="col-5 align-self-center">
                <Card
                    resource={<ResourceCompact resource={props.edge.source} />}
                    footer={props.edge.source.from_user ? <Link to={`/resource/` + props.edge.source.id} className="btn btn-light w-100">{t('edit')}</Link> : undefined}
                />
            </div>
        </div>
    );
};


export const Alternative = (props) => {
    const { t, i18n } = useTranslation();

    const remove = () => {
        props.removeEdge(props.edge);
    }

    let leftContent = null;
    let rightContent = null;
    if (props.edge.resources.length === 2) {
        leftContent = props.edge.resources[0];
        rightContent = props.edge.resources[1];
    } else if (props.edge.topics.length === 2) {
        leftContent = props.edge.topics[0];
        rightContent = props.edge.topics[1];
    } else {
        leftContent = props.edge.resources[0];
        rightContent = props.edge.topics[0];
    }

    return (
        <div className="row mb-5">
            <div className="col-5 align-self-center">
                <Card
                    resource={<ResourceCompact resource={leftContent} />}
                    footer={leftContent.from_user ? <Link to={`/resource/` + leftContent.id} className="btn btn-light w-100">{t('edit')}</Link> : undefined}
                />
            </div>
            <div className="col-2 align-self-center">
                {props.edge.approved
                    ?
                    <div className='row'>
                        <div className='text-center mb-4'>
                            <h4><i className="fa-solid fa-check"></i></h4>
                        </div>
                    </div>
                    :
                    <></>}
                <div className='row'>
                    <div className='text-center mb-4'>
                        <button className="btn btn-light w-100" onClick={remove}>{t('delete')}</button>
                    </div>
                </div>
            </div>
            <div className="col-5 align-self-center">
                <Card
                    resource={<ResourceCompact resource={rightContent} />}
                    footer={rightContent.from_user ? <Link to={`/resource/` + rightContent.id} className="btn btn-light w-100">{t('edit')}</Link> : undefined}
                />
            </div>
        </div>
    );
};
