import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class Alerts extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        message: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
        const { error, alert, message, t, i18n } = this.props;
        
        if (error !== prevProps.error) {
            if (error.msg.name) alert.error(t('username') + `: ${error.msg.name.join()}`);
            if (error.msg.email) alert.error(t('email') + `: ${error.msg.email.join()}`);
            if (error.msg.message) alert.error(t('message') + `: ${error.msg.message.join()}`);
            if (error.msg.non_field_errors) alert.error(error.msg.non_field_errors.join());
            if (error.msg.username) alert.error(error.msg.username.join());
            if (error.msg.detail) alert.error(error.msg.detail);
            if (error.msg.invalidLink) alert.error(error.msg.invalidLink);
        }

        if (message !== prevProps.message) {
            if (message.passwordNotMatch) alert.error(message.passwordNotMatch);
            if (message.feedbackSuccess) alert.success(message.feedbackSuccess);
            if (message.edgeSubmitted) alert.success(message.edgeSubmitted);
            if (message.goalCompleted) alert.success(message.goalCompleted);
            if (message.goalReactivated) alert.success(message.goalReactivated);
            if (message.resourceSubmitted) alert.success(message.resourceSubmitted);
            if (message.topicExtended) alert.success(message.topicExtended);
        }
    }

    render() {
        return <Fragment />;
    }
}

const mapStateToProps = (state) => ({
    error: state.errors,
    message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(withTranslation()(Alerts)));
