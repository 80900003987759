import store from "../store";
import axios from "axios";
import { tokenConfig } from "../actions/auth"


const http = () => {
    return axios.create({
        baseURL: 'https://kraphx-backend.herokuapp.com/api/',
        ...tokenConfig(() => { return { 'auth': store.getState().auth } }),
    });
}


const getResourceProposals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset; 
    return http().get(`resource_proposals/?limit=${limit}&offset=${offset}`);
};

const getResourceProposal = id => {
    return http().get(`resource_proposals/${id}`);
};

const updateResourceProposal = (id, payload) => {
    return http().put(`resource_proposals/${id}/`, payload);
};

const addResourceProposal = payload => {
    return http().post(`resource_proposals/`, payload);
};

const getDependencyEdgeProposals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`dependency_edge_proposals/?limit=${limit}&offset=${offset}`);
};

const getEdgeProposals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`edge_proposals/?limit=${limit}&offset=${offset}`);
};

const getKnowledge = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`knowledge/?limit=${limit}&offset=${offset}`);
};

const addKnowledge = (resourceId) => {
    const payload = { 'pk': resourceId };
    return http().post("knowledge/", payload);
};

const getResource = id => {
    return http().get(`resources/${id}`);
};

const getResourceNeighborhood = id => {
    return http().get(`resource_proposal_neighborhood/${id}`);
};

const getTopic = id => {
    return http().get(`topics/${id}`);
};

const getTopicProposal = id => {
    return http().get(`topic_proposals/${id}`);
};

const getTopicProposals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`topic_proposals/?limit=${limit}&offset=${offset}`);
};

const updateTopicProposal = (id, payload) => {
    return http().put(`topic_proposals/${id}/`, payload);
};

const addTopicProposal = payload => {
    return http().post(`topic_proposals/`, payload);
};

const getResourceMeta = () => {
    return http().get("resource_meta/");
};

const deleteResourceProposal = id => {
    return http().delete(`resource_proposals/${id}`);
};

const deleteTopicProposal = id => {
    return http().delete(`topic_proposals/${id}`);
};

const deleteDependencyEdgeProposal = id => {
    return http().delete(`dependency_edge_proposals/${id}`);
};

const deleteEdgeProposal = id => {
    return http().delete(`edge_proposals/${id}`);
};

const deleteSimilarityProposal = id => {
    return http().delete(`similarity/${id}`);
};

const addDependencyEdgeProposal = (sourceId, destinationId) => {
    const payload = {
        source: sourceId,
        destination: destinationId
    };
    return http().post("dependency_edge_proposals/", payload);
};

const addEdgeProposal = (sourceId, destinationId) => {
    const payload = {
        source: sourceId,
        destination: destinationId
    };
    return http().post("edge_proposals/", payload);
};

const addDependency = (sourceId, destinationId) => {
    return http().post(`dependencies/${sourceId}_${destinationId}/`, null);
};

const getGoals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`goals/?limit=${limit}&offset=${offset}`);
};

const getCompletedGoals = (offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`completed_goals/?limit=${limit}&offset=${offset}`);
};

const deleteGoal = id => {
    return http().delete(`goals/${id}/`);
};

const getGoal = id => {
    return http().get(`goals/${id}/`);
};

const addGoal = payload => {
    return http().post(`goals/`, payload);
};

const getEdges = goalId => {
    return http().get(`edges/${goalId}/`);
};

const getRecommendations = goalId => {
    return http().get(`recommendations/${goalId}/`);
};

const getDependencies = (goalId, step) => {
    return http().get(`dependencies/${goalId}_${step}/`);
};

const getFeatured = () => {
    return http().get("featured/");
};

const addEdge = (goalId, resourceId, position, consolidationTarget) => {
    const payload = { goal_pk: goalId, resource_pk: resourceId, position: position, consolidation_target: consolidationTarget };
    return http().post(`edges/`, payload);
};

const deleteEdge = id => {
    return http().delete(`edges/${id}/`);
};

const completeGoal = id => {
    return http().post(`complete/${id}/`, null);
};

const activateGoal = id => {
    return http().delete(`complete/${id}/`);
};

const search = (searchTerm, includeTopics, app, offset, limit) => {
    limit = limit == undefined ? 50 : limit; 
    offset = offset == undefined ? 0 : offset;
    const encodedSearchTerm = encodeURIComponent(searchTerm.trim());
    if (encodedSearchTerm) {
        const payload = { 
            'query': encodedSearchTerm, 
            'topics': includeTopics != undefined ? includeTopics : true,
            'app': app
        };
        return http().post(`search/?limit=${limit}&offset=${offset}`, payload);
    }
};

const resourceSearch = (searchTerm, offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    const encodedSearchTerm = encodeURIComponent(searchTerm.trim());
    if (encodedSearchTerm) {
        const payload = { 
            'query': encodedSearchTerm
        };
        return http().post(`resource_search/?limit=${limit}&offset=${offset}`, payload);
    }
};

const topicSearch = (searchTerm, offset, limit) => {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    const encodedSearchTerm = encodeURIComponent(searchTerm.trim());
    if (encodedSearchTerm) {
        const payload = { 
            'query': encodedSearchTerm
        };
        return http().post(`topic_search/?limit=${limit}&offset=${offset}`, payload);
    }
};

const rateEdge = (goalId, step, payload) => {
    return http().post(`rating/${goalId}_${step}/`, payload);
};

const rateDependency = (destinationId, sourceId, payload) => {
    return http().post(`dependency_rating/${destinationId}_${sourceId}/`, payload);
};

const getPreview = link => {
    return http().post('content_preview/', { link: link })
}

const getEdge = id => {
    return http().post('edge/', { pk: id })
}

function addSimilarity() {
    let payload = {};
    for (var i = 0; i < arguments.length; i++) {
        payload[i] = arguments[i];
    }
    return http().post('similarity/', payload)
}

function getSimilarityProposals(offset, limit) {
    limit = limit == undefined ? 10 : limit; 
    offset = offset == undefined ? 0 : offset;
    return http().get(`similarity/?limit=${limit}&offset=${offset}`);
}

const expandRecommendation = (goalId, resourceId) => {
    const payload = { 'goal_pk': goalId, 'resource_pk': resourceId };
    return http().post('recommendations/', payload)
}

const getProfile = () => {
    return http().get('profile/')
}

const setProfile = (attribute) => {
    const payload = { attribute: attribute };
    return http().post('profile/', payload)
}

const getConsolidations = (resourceId) => {
    const payload = { pk: resourceId };
    return http().post('consolidations/', payload)
}

const uploadFile = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return http().post("file/", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

const extendTopic = (topicId, contentId) => {
    const payload = { content_pk: contentId, topic_pk: topicId };
    return http().post('extend_topic/', payload)
}

const DataService = {
    getResourceProposals,
    getResourceProposal,
    updateResourceProposal,
    addResourceProposal,
    getDependencyEdgeProposals,
    getEdgeProposals,
    getKnowledge,
    addKnowledge,
    getResource,
    getResourceMeta,
    deleteResourceProposal,
    deleteDependencyEdgeProposal,
    deleteEdgeProposal,
    addDependencyEdgeProposal,
    addEdgeProposal,
    addDependency,
    getTopicProposal,
    updateTopicProposal,
    addTopicProposal,
    getGoals,
    getCompletedGoals,
    deleteGoal,
    getGoal,
    addGoal,
    getEdges,
    getRecommendations,
    addEdge,
    deleteEdge,
    getDependencies,
    completeGoal,
    activateGoal,
    search,
    getTopicProposals,
    rateEdge,
    rateDependency,
    getPreview,
    getEdge,
    addSimilarity,
    expandRecommendation,
    getProfile,
    setProfile,
    getTopic,
    getConsolidations,
    uploadFile,
    deleteTopicProposal,
    getSimilarityProposals,
    deleteSimilarityProposal,
    getResourceNeighborhood,
    resourceSearch,
    topicSearch,
    getFeatured,
    extendTopic,
};

export default DataService;
