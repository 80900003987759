import React from 'react';
import { Card } from './Utils';
import { Link } from "react-router-dom";
import { ResourceCompact, ResourceMinimal } from './Resources';
import { useTranslation } from 'react-i18next';
import '../../languages/i18n';


export const LearningGoal = (props) => {
    const { t, i18n } = useTranslation();

    const deleteGoal = () => {
        props.deleteGoal(props.goal)
    };

    return (
        <Card
            resource={
                <>
                    <ResourceCompact
                        resource={props.goal.resource ? props.goal.resource : props.goal.topic}
                        done={props.goal.path_length}
                        todo={props.goal.recommendations_length}
                    />
                    {props.goal.path_length > 0
                        ?
                        <>
                            <p></p>
                            <p className='mt-auto'>{t('recentResource')}</p>
                            <div style={{ "height": "120px" }}>
                                <div className="card shadow-sm h-100">
                                    <div className="card-body">
                                        <ResourceMinimal resource={props.goal.recent_resource} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </>
            }
            footer={
                <div className='row'>
                    <div className='col-6'>
                        <div className='text-center'>
                            <Link 
                                to={"/goal/" + props.goal.id} 
                                className="btn primary me-2 w-100">
                                    {props.goal.path_length > 0 ? t('continue') : t('start')}
                            </Link>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='text-center'>
                            <button 
                                className="btn btn-light ms-2 w-100" 
                                onClick={deleteGoal}>{t('deleteGoal')}
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};
