import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const PrivateRoute = ({ children, auth, t }) => {
    if (auth.isLoading) {
        return <h2>{t("loading")}</h2>;
    } else if (!auth.isAuthenticated) {
        return <Navigate to="/login" />;
    } else {
        return children;
    }
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(withTranslation()(PrivateRoute));