import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { returnErrors } from "../../actions/messages";
import DataService from "../../services/DataService";
import { Carousel } from "./Utils";
import { Link, useNavigate } from 'react-router-dom';
import { ResourceCompactSelect } from "./ResourceUtils";
import { useTranslation } from 'react-i18next';
import { mergeArrays } from "./Utils";
import '../../languages/i18n';


export const Search = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [gitHub, setGitHub] = useState(false);
    const [semanticScholar, setSemanticScholar] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const onChangeSearchTitle = e => {
        props.setSearchTerm(e.target.value);
    };

    const handleKeyPress = e => {
        if (e.charCode == 13) {
            if (props.redirecting) {
                navigate("/search/" + encodeURIComponent(props.searchTerm));
            } else {
                searchResources('kraphx');
            }
        }
    };

    const searchResources = (app) => {
        if (props.searchTerm) {
            // this triggers the loading spinner
            if (app === "kraphx") {
                if (props.setRankingPrioritization) props.setRankingPrioritization("");
                setSearchActive(true);
                props.setSearchResults(null);
                setGitHub(false);
                setSemanticScholar(false);
            }
            setLoading(true);
            // internal search
            DataService.search(props.searchTerm, props.includeTopics, app)
                .then((res) => {
                    const results = res.data.results;
                    // if external search ends before internal search, api returns [null]. In this case,
                    // searchResults should not get set as the internal search results are still missing.
                    if ((results.length > 0 && results[0] !== null) || results.length === 0) {
                        props.setSearchResults(searchResults => { return mergeArrays(searchResults, results) });
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    dispatch(returnErrors(err.response.data, err.response.status));
                });
        }
    };

    return (
        <>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder={t("enterQuery")}
                    name="searchTitle"
                    value={props.searchTerm}
                    onChange={onChangeSearchTitle}
                    onKeyPress={handleKeyPress}
                />
            </div>

            {!props.redirecting && searchActive
                ? <div className="row mb-3 mt-3">
                    <div className="col-4">
                        <button className="btn primary w-100">KraphX</button>
                    </div>
                    <div className="col-4">
                        <button
                            className={semanticScholar ? "btn primary w-100" : "btn btn-light w-100"}
                            onClick={() => {
                                if (!semanticScholar) {
                                    searchResources('semantic_scholar');
                                    setSemanticScholar(true);
                                    if (props.setRankingPrioritization) props.setRankingPrioritization('www.semanticscholar.org');
                                }
                            }}>
                            Semantic Scholar
                        </button>
                    </div>
                    <div className="col-4">
                        <button
                            className={gitHub ? "btn primary w-100" : "btn btn-light w-100"}
                            onClick={() => {
                                if (!gitHub) {
                                    searchResources('github');
                                    setGitHub(true);
                                    if (props.setRankingPrioritization) props.setRankingPrioritization('github.com');
                                }
                            }}>
                            GitHub
                        </button>
                    </div>
                </div>
                : <></>
            }

            <div className='text-center mt-3'>
                {loading && props.searchResults != undefined
                    ?
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t('loading')}</span>
                    </div>
                    : <></>
                }
            </div>
        </>
    );
};


export const SearchSelect = (props) => {
    const [searchResults, setSearchResults] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [rankingPrioritization, setRankingPrioritization] = useState("");

    useEffect(() => {
        setSearchResults(props.searchResults);
        setSearchTerm(props.searchTerm);
    }, [props.searchResults, props.searchTerm]);

    return (
        <>
            <div className='mx-auto w-75 mb-5'>
                <Search
                    setSearchResults={setSearchResults}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchResults={searchResults}
                    setLoading={setLoading}
                    setRankingPrioritization={setRankingPrioritization}
                />
            </div>
            <SearchResultsSelect
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                select={props.select}
                deselect={props.deselect}
                selectText={props.selectText}
                deselectText={props.deselectText}
                isSelected={props.isSelected}
                loading={loading}
                prioritize={rankingPrioritization}
            />
        </>
    );
};


export const SearchResultsSelect = (props) => {
    // sort all search results into bins based on their url
    const { t, i18n } = useTranslation();
    const isSelected = props.isSelected == undefined ? () => { return false } : (content) => { return props.isSelected(content) };
    const selectText = props.selectText == undefined ? t('select') : props.selectText;
    const deselectText = props.deselectText == undefined ? t('deselect') : props.deselectText;

    let resultView = <></>;
    // props.searchResults === undefined must own an if statement 
    if (props.searchResults === undefined) {
        resultView = <></>;
    } else if (props.searchResults === null) {
        resultView =
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t('loading')}</span>
                </div>
            </div>
    } else {
        let hostnames = {};
        props.searchResults.forEach((result, ix) => {
            const resourceComponent = <ResourceCompactSelect
                resource={result}
                isSelected={isSelected(result)}
                select={props.select}
                deselect={props.deselect}
                selectText={selectText}
                deselectText={deselectText}
                key={result.id}
            />
            let link = result.link;
            if (link == undefined) {
                link = 'http://kraphx.com';
            } else {
                link = link.trim();
            }
            if (!(link.slice(0, 7) == 'http://') && !(link.slice(0, 8) == 'https://')) {
                link = 'http://' + link;
            }
            let { hostname } = new URL(link);
            if (result.from_user) {
                hostname = t('createdByUser')
            }
            hostname in hostnames ? hostnames[hostname].push(resourceComponent) : hostnames[hostname] = [resourceComponent];
        });
        // one carousel per bin, bins with only one resource get merged
        const otherHosts = [];
        const hostSections = [];
        for (const hostname in hostnames) {
            if (hostnames[hostname].length == 1 && hostname != t('createdByUser')) {
                otherHosts.push(hostnames[hostname][0]);
            } else {
                // resources that were created by the user should come first
                if (hostname == t('createdByUser') && props.prioritize == "") {
                    hostSections.unshift(
                        <div key={hostname}>
                            <Carousel components={hostnames[hostname]} title={hostname} id={'createdByUser'} />
                        </div>
                    )
                } else if (props.prioritize == hostname) {
                    hostSections.unshift(
                        <div key={hostname}>
                            <Carousel components={hostnames[hostname]} title={hostname} id={hostname.replace(/[\.\n\r\s\t]+/g, '_')} />
                        </div>
                    )
                } else {
                    hostSections.push(
                        <div key={hostname}>
                            <Carousel components={hostnames[hostname]} title={hostname} id={hostname.replace(/[\.\n\r\s\t]+/g, '_')} />
                        </div>
                    )
                }
            }
        }
        if (otherHosts.length > 0) {
            // merged web results should appear after user created resources or at first position
            let otherPosition = 0;
            if (t('createdByUser') in hostnames) otherPosition += 1;
            if ('kraphx.com' in hostnames) otherPosition += 1;
            if (props.prioritize != '') otherPosition += 1;
            hostSections.splice(otherPosition, 0,
                <div key={'Other'}>
                    <Carousel components={otherHosts} title={t('webResults')} id={'Other'} />
                </div>
            );
        }
        resultView =
            <div>
                <hr />
                <div className="row mt-5 mb-5">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <h4 className='text-center'>{t('searchResults')}&ensp;
                            {props.loading
                                ? <div className="spinner-border" role="status">
                                    <span className="visually-hidden">{t('loading')}</span>
                                </div>
                                : <></>}
                        </h4>
                    </div>
                    <div className="col-4">
                        <div className="text-end">
                            <Link to={"/addResource"} className='btn btn-light'>{t('addMissing')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        if (hostSections.length > 0) {
            resultView =
                <>
                    {resultView}
                    {hostSections}
                </>
        } else {
            resultView =
                <>
                    {resultView}
                    <div className="text-center">{t('noContents')}</div>
                </>
        }
    }

    return (resultView);
};
