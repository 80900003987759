import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import '../../css/Header.css';
import { HomeTutorial, ResourcesTutorial, PathTutorial, RelationsTutorial, SearchResultTutorial, TopicsTutorial } from '../interface/Tutorial';
import { ModalBody } from '../interface/Utils';
import { withTranslation } from 'react-i18next';
import DataService from '../../services/DataService';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { v4 as uuidv4 } from 'uuid';


const changeLanguage = (i18n, language) => {
    i18n.changeLanguage(language);
    DataService.setProfile(language)
        .then((res) => { })
        .catch((err) => { });
};


export class Header extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showHomeModal: false,
            showSearchResultModal: false,
            showPathModal: false,
            showResourcesModal: false,
            showTopicsModal: false,
            showRelationsModal: false,
        };
    }

    render() {
        const { isAuthenticated, user } = this.props.auth;
        const { t, i18n } = this.props;
        const authLinks = (
            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                <span className="navbar-text me-3">
                    <strong>{user ? t('welcome') + ` ${user.username}` : ''}</strong>
                </span>
                <li className="nav-item">
                    <div className="dropdown">
                        <button className="nav-link btn btn-dark btn-sm text-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fas fa-plus-square" />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={"/addRelation"} state={{ name: uuidv4() }} className='dropdown-item'>{t('addRelation')}</Link>
                            <Link to={"/addResource"} state={{ name: uuidv4() }} className='dropdown-item'>{t('addResource')}</Link>
                            <Link to={"/addTopic"} state={{ name: uuidv4() }} className='dropdown-item'>{t('addTopic')}</Link>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <Link to={"/profile"} className="nav-link btn btn-dark btn-sm text-light"><i className="fas fa-user" /></Link>
                </li>
                <li className="nav-item">
                    <div className="dropdown">
                        <button className="nav-link btn btn-dark btn-sm text-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa-solid fa-circle-question"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className='dropdown-item btn' onClick={() => this.setState({ showHomeModal: true })}>{t('kraphx')}</a>
                            <a className='dropdown-item btn' onClick={() => this.setState({ showSearchResultModal: true })}>{t('openSearchDoc')}</a>
                            <a className='dropdown-item btn' onClick={() => this.setState({ showPathModal: true })}>{t('openLearningPathDoc')}</a>
                            <a className='dropdown-item btn' onClick={() => this.setState({ showRelationsModal: true })}>{t('openRelationDoc')}</a>
                            <a className='dropdown-item btn' onClick={() => this.setState({ showResourcesModal: true })}>{t('openResourceDoc')}</a>
                            <a className='dropdown-item btn' onClick={() => this.setState({ showTopicsModal: true })}>{t('openTopicDoc')}</a>
                            <div className="dropdown-divider"></div>
                            <a className='dropdown-item btn' href='https://forms.gle/Y7KmHDp5u8wY85j87'>{t('sendFeedback')}</a>
                            <a className='dropdown-item btn' href='https://paypal.me/KraphX'>{t('support')}</a>
                            <a className='dropdown-item btn' href='https://www.kraphx.com'>{t('contact')}</a>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <a href='https://www.kraphx.com/privacy.html' className="nav-link btn btn-dark btn-sm text-light">
                        {t('privacy')}
                    </a>
                </li>
                <li className="nav-item">
                    <a href='https://www.kraphx.com/about.html' className="nav-link btn btn-dark btn-sm text-light">
                        {t('about')}
                    </a>
                </li>
                <li className="nav-item">
                    <div className="dropdown">
                        <button className="nav-link btn btn-dark btn-sm text-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {i18n.language.toUpperCase()}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className='dropdown-item btn' onClick={() => { changeLanguage(i18n, 'de') }}>{t('german')}</a>
                            <a className='dropdown-item btn' onClick={() => { changeLanguage(i18n, 'en') }}>{t('english')}</a>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <button onClick={this.props.logout} className="nav-link btn btn-dark btn-sm text-light">
                        {t('logout')}
                    </button>
                </li>
            </ul>
        );

        const guestLinks = (
            <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <a href='https://www.kraphx.com/privacy.html' className="nav-link btn btn-dark btn-sm text-light">
                        {t('privacy')}
                    </a>
                </li>
                <li className="nav-item">
                    <a href='https://www.kraphx.com/about.html' className="nav-link btn btn-dark btn-sm text-light">
                        {t('about')}
                    </a>
                </li>
                <li className="nav-item">
                    <div className="dropdown">
                        <button className="nav-link btn btn-dark btn-sm text-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {i18n.language.toUpperCase()}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className='dropdown-item btn' onClick={() => { i18n.changeLanguage('de') }}>{t('german')}</a>
                            <a className='dropdown-item btn' onClick={() => { i18n.changeLanguage('en') }}>{t('english')}</a>
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <Link to="/register" className="nav-link btn btn-dark btn-sm text-light">
                        {t('register')}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/login" className="nav-link btn btn-dark btn-sm text-light">
                        {t('login')}
                    </Link>
                </li>
            </ul>
        );

        return (
            <>
                <nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-5">
                    <div className="container">
                        <div className="collapse navbar-collapse">
                            <a className="navbar-brand header-logo" href="">
                                {t('kraphx')}
                            </a>
                        </div>
                        {isAuthenticated ? authLinks : guestLinks}
                    </div>
                </nav>
                <Offcanvas show={this.state.showHomeModal} onHide={() => { this.setState({ showHomeModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <HomeTutorial setProfile={false} setShow={(show) => { this.setState({ showHomeModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={this.state.showSearchResultModal} onHide={() => { this.setState({ showSearchResultModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <SearchResultTutorial setProfile={false} setShow={(show) => { this.setState({ showSearchResultModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={this.state.showPathModal} onHide={() => { this.setState({ showPathModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <PathTutorial setProfile={false} setShow={(show) => { this.setState({ showPathModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={this.state.showRelationsModal} onHide={() => { this.setState({ showRelationsModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <RelationsTutorial setProfile={false} setShow={(show) => { this.setState({ showRelationsModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={this.state.showResourcesModal} onHide={() => { this.setState({ showResourcesModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <ResourcesTutorial setProfile={false} setShow={(show) => { this.setState({ showResourcesModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas show={this.state.showTopicsModal} onHide={() => { this.setState({ showTopicsModal: false }) }} scroll={true} backdrop={true} placement={'end'}>
                    <Offcanvas.Body>
                        <TopicsTutorial setProfile={false} setShow={(show) => { this.setState({ showTopicsModal: show }) }} />
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});


export default connect(mapStateToProps, { logout })(withTranslation()(Header));